import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Footer from "../../components/footer2Versao";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Logo from "../../img/logo2x.png";
import { HashLink as Link } from "react-router-hash-link";
import InputMask from "react-input-mask";
import {
  urlPessoas,
  entregarIdLogado,
  entregarEmailLogado,
  token,
} from "../../servicos/pessoas-service";
import swal from "sweetalert";
import { Redirect } from "react-router";

class cadastroDadosPessoais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecionar: false,
      redirecionarHome: false,
      aceito: false,
    };
    this.cadastrarForm = this.cadastrarForm.bind(this);
    this.aceiteDeTermos = this.aceiteDeTermos.bind(this);
  }

  aceiteDeTermos() {
    this.setState({ aceito: this.state.aceito ? false : true });
  }

  cadastrarForm(event) {
    let nome = document.getElementById("formCadastro").nomeInput.value;
    let cpf = document.getElementById("formCadastro").cpfInput.value;
    let aniversario =
      document.getElementById("formCadastro").aniverssarioInput.value;
    let telefone = document.getElementById("formCadastro").telefoneInput.value;
    let profissao =
      document.getElementById("formCadastro").profissaoInput.value;
    let estadoCivil =
      document.getElementById("formCadastro").estadoCivilInput.value;

    if (!this.state.aceito) {
      swal(
        "Você deve aceitar nossos Termos de uso e Politicas de privacidade."
      );
    } else {
      fetch(urlPessoas + "Cliente/CadastrarDadosPessoais", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          id: entregarIdLogado,
          cpf: cpf,
          nome: nome,
          dataNasimento: aniversario,
          telefone: telefone,
          emailContato: entregarEmailLogado,
          profissao: profissao,
          estadoCivil: estadoCivil,
          aceitaTermosUsoEhPoliticaProvacidade: this.state.aceito,
        }),
      })
        .then((resposta) => resposta.json())
        .then((resultado) => {
          if (resultado.mensagem) {
            swal(resultado.mensagem);
          } else if (resultado.enderecos.length === 0) {
            this.setState({ redirecionar: true });
          } else {
            this.setState({ redirecionarHome: true });
          }
        })
        .catch((err) => {
          console.log("Erro ao se conectar ao servidor: " + err);
        });
    }
    event.preventDefault();
  }

  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/cadastroEndereco" />;
    } else if (this.state.redirecionarHome == true) {
      return <Redirect to="/" />;
    } else {
      const corSetasSelecionadas = {
        backgroundColor: "#E6E7E8",
      };

      return (
        <Container fluid id="inteiro">
          <Container fluid id="cadastroInteiro">
            <Container fluid id="headerCadastro">
              <Row id="cadastroInteiro">
                <Col xs={6} md={4} id="headerCadastroEsquerda">
                  <Link to="/">
                    <Image
                      src={Logo}
                      width="127"
                      height="37"
                      fluid
                      id="imgLogo2"
                    />
                  </Link>
                </Col>
                <Col xs={12} md={8} id="headerCadastroDireita">
                  <Row id="setas">
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec" />
                      <div id="setaInversaBaixoSelec" />
                      <div id="rectangleSelec">
                        <p id="escritoSetaPlanoSelec">passo 01</p>
                        <p id="escritoSetaDescricaoSelec">Planos</p>
                      </div>
                      <div id="setaSelec" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec2" />
                      <div id="setaInversaBaixoSelec2" />
                      <div id="rectangleSelec2">
                        <p id="escritoSetaPlanoSelec">passo 02</p>
                        <p id="escritoSetaDescricaoSelec">Dados</p>
                      </div>
                      <div id="setaSelec2" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCima" />
                      <div id="setaInversaBaixo" />
                      <div id="rectangle">
                        <p id="escritoSetaPlano">passo 03</p>
                        <p id="escritoSetaDescricao">Endereço</p>
                      </div>
                      <div id="seta" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCima" />
                      <div id="setaInversaBaixo" />
                      <div id="rectangle">
                        <p id="escritoSetaPlano">passo 04</p>
                        <p id="escritoSetaQuest">Questionário</p>
                      </div>
                      <div id="seta" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCima" />
                      <div id="setaInversaBaixo" />
                      <div id="rectangle">
                        <p id="escritoSetaPlano">passo 05</p>
                        <p id="escritoSetaDescricao">Pagamento</p>
                      </div>
                      <div id="seta" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCima" />
                      <div id="setaInversaBaixo" />
                      <div id="rectanglePonta">
                        <p id="escritoSetaObrigado">Obrigado!</p>
                      </div>
                    </Container>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid id="conteudoCadastroInteiro">
            <Container fluid id="conteudoCadastro">
              <Container fluid id="monteSeuPlano">
                02 - Preencha seus dados.
              </Container>
              <Container fluid id="menuMonteSeuPlano">
                <form method="post" id="formCadastro">
                  <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                    <label htmlFor="nomeInput" id="formLabel">
                      Nome:
                    </label>
                    <input
                      type="text"
                      name="nomeInput"
                      className="formInput"
                      id="input-nome"
                      size="48"
                    />
                  </Col>
                  <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                    <label htmlFor="cpfInput" id="formLabel">
                      CPF:
                    </label>
                    <InputMask
                      mask="999.999.999-99"
                      value={this.props.value}
                      onChange={this.props.onChange}
                      type="text"
                      name="cpfInput"
                      id="input-cpf"
                      className="formInput"
                      size="49"
                    />
                  </Col>
                  <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                    <label htmlFor="aniverssarioInput" id="formLabel">
                      Data de Nascimento:
                    </label>
                    <InputMask
                      mask="99/99/9999"
                      value={this.props.value}
                      onChange={this.props.onChange}
                      type="text"
                      name="aniverssarioInput"
                      className="formInput"
                      id="input-aniversario"
                      size="35"
                    />
                  </Col>
                  <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                    <label htmlFor="telefoneInput" id="formLabel">
                      Telefone:
                    </label>
                    <InputMask
                      mask="(99) 99999-9999"
                      value={this.props.value}
                      onChange={this.props.onChange}
                      type="text"
                      name="telefoneInput"
                      className="formInput"
                      id="input-telefone"
                      size="46"
                    />
                  </Col>
                  <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                    <label htmlFor="profissaoInput" id="formLabel">
                      Profissão:
                    </label>
                    <input
                      type="text"
                      name="profissaoInput"
                      className="formInput"
                      id="input-profissao"
                      size="45"
                    />
                  </Col>
                  <Col xs={12} md={12} style={{ padding: "0", margin: "0" }}>
                    <label htmlFor="estadoCivilInput" id="formLabel">
                      Estado Civil:
                    </label>
                    <input
                      type="text"
                      name="estadoCivilInput"
                      className="formInput"
                      id="input-civil"
                      size="43"
                    />
                  </Col>
                  <Container
                    className="imputCheckbox"
                    style={{ marginTop: "15px", marginBottom: "-40px" }}
                  >
                    <input
                      className="mainClienteImputCheckbox"
                      onChange={this.aceiteDeTermos}
                      type="checkbox"
                      value={this.state.aceito}
                      name="aceite"
                    />
                    <label className="mainClienteLabel">
                      <p>
                        Li e aceito as{" "}
                        {/* <Link to="/TermosDeUso">termos de uso</Link> e{" "} */}
                        <a
                          href="https://www.leituratua.com.br/documentos/Pol%C3%ADtica+de+privacidade+da+empresa+LeituraTua.pdf"
                          target="_blank"
                        >
                          políticas de privacidade
                        </a>
                        .
                      </p>
                    </label>
                  </Container>
                </form>
              </Container>
              <Container id="botaoCadastro">
                <ButtonGroup id="menuBotaoAssineMesesCadastro">
                  <Button variant="primary" id="botaoCadastroVoltar">
                    <Link to="/cadastro">
                      <h6>Voltar</h6>
                    </Link>
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoCadastroAvancar"
                    onClick={this.cadastrarForm}
                  >
                    <h6 id="textoBotaoAvancar">Próximo</h6>
                  </Button>
                </ButtonGroup>
              </Container>
            </Container>
          </Container>

          <Footer />
        </Container>
      );
    }
  }
}

export default cadastroDadosPessoais;
