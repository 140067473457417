import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ImgInteira from "../../img/pessoLendo.jpg";

function quemSomos() {
  return (
    <Container fluid id="inteiro">
      <Header id="cabecalho" />

      <Container fluid id="imagem">
        <Image
          src={ImgInteira}
          id="img2"
          width="1281"
          height="397"
          className="imgCarrosel"
        />
      </Container>
      <Container fluid id="ConteudoQuemSomos">
        <p id="titulo">QUEM SOMOS?</p>
        <p>
          O Leituratua é o{" "}
          <b>
            primeiro clube brasileiro de assinaturas de livros e avaliações
            biblioterapêuticas personalizadas.{" "}
          </b>
        </p>

        <p>
          Somos amantes dos livros, das pessoas, dos animais também, é claro!, e
          de uma vida boa de bem-estar para todos.{" "}
          <b>Acreditamos nos livros como ferramentas </b> particulares para{" "}
          <b>a vida, o amor e a criatividade</b>, instrumentos para o leitor
          buscar e desenvolver seus talentos a partir de uma base de
          autoconhecimento amoroso e criativo.
        </p>

        <p>
          Cada pessoa tem vivências particulares e modos únicos de experimentar
          essas vivências que podem ser acessadas por suas respostas à vida e à
          cultura em geral. Por isso, estamos convencidos de que podemos indicar
          a você livros para seu momento especial de vida ou para sua vida em
          geral, de acordo com suas respostas a nosso questionário criado para
          transformar suas respostas em indicações de livros mais adequados para
          o seu perfil e suas condições sociais, intelectuais e emocionais desse
          período de sua vida, mas que também podem servir a ela como um todo.
        </p>

        <p>
          Nossos avaliadores <b>leem cada questionário atentamente</b> para
          recomendar livros especiais a cada associado, de acordo com o que você
          nos conta ao responder a questões pessoais e culturais, de forma
          sigilosa. Cada livro é acompanhado de um texto que explica{" "}
          <b>
            porque esse livro específico serve para aquele leitor em especial
          </b>
          , como se fosse uma “conversa” com nosso analista que ama livros e
          gente, buscando <b>juntar obras e leitores</b> por meio do{" "}
          <b>afeto e da inteligência</b>.
        </p>
      </Container>
      <Container fluid id="conteudoParaQueServimos">
        <p id="titulo">PARA QUE SERVIMOS?</p>
        <p>
          Para você ter mais <b>vida, amor e criatividade</b> nós servimos.
        </p>

        <p>
          O mundo que desejamos e estamos criando com nosso trabalho é um espaço
          mais coletivista e generoso, onde as pessoas desenvolvem seus talentos
          para sua alegria e o bem de todos. Nossa missão é servir às pessoas
          ajudando-as a se conhecerem melhor e descobrirem tantos potenciais,
          qualidades e possibilidades que elas têm dificuldades de acessar
          sozinhas. Usamos, para ajudar esses seres humanos que consideramos
          realmente valiosos e maravilhosos, os livros! Sim, os livros são
          ferramentas poderosas de autoconhecimento e conhecimento da aventura
          humana, especialmente quando{" "}
          <b>são para você, especialmente para você</b>, quando um ser humano
          que desenvolveu habilidades profissionais para usar a inteligência e o
          afeto ao escolher livros para outra pessoa, baseando-se nas respostas
          dessa pessoa a uma ferramenta elaborada com técnica e carinho, os
          indica <b>para o seu caso</b>. O resultado disso pode ser a relação
          entre obra e leitor trazendo mais bem-estar à existência desse leitor.
          Nossa missão é facilitar esse encontro, ajudando os associados a terem{" "}
          <b>mais vida, mais amor e mais criatividade</b>, a partir da leitura
          de obras indicadas de <b>modo personalizado</b> para cada um.
        </p>
      </Container>

      <Footer />
    </Container>
  );
}

export default quemSomos;
