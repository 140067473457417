// export var urlQuestionario = "https://localhost:44390/api/Questionario/";
export var urlQuestionario = "https://api.leituratua.com.br/api/Questionario/";

export var respostaGuardadaOutros = "";

export const guardarValorOutros = function (resposta) {
  respostaGuardadaOutros = resposta;
};

// export const entregarValorOutros = function(){
//    return
// }

export const mockQuestionario = [
  {
    idPergunta: 1,
    numeroPergunta: 1,
    pergunta: "QUAL É A SUA IDADE?",
    quantasRespostasValidas: 1,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "18 a 25 anos",
    textoResposta2: "26 a 30 anos",
    textoResposta3: "31 a 35 anos",
    textoResposta4: "36 a 45 anos",
    textoResposta5: "46 a 55 anos",
    textoResposta6: "56 a 65 anos",
    textoResposta7: "66 a 75 anos",
    textoResposta8: "76 anos ou mais",
    textoResposta9: null,
    textoResposta10: null,
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 2,
    numeroPergunta: 2,
    pergunta: "QUAL É O SEU GÊNERO?",
    quantasRespostasValidas: 1,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "feminino",
    textoResposta2: "masculino",
    textoResposta3: "mulher trans",
    textoResposta4: "homem trans",
    textoResposta5: "outros",
    textoResposta6: null,
    textoResposta7: null,
    textoResposta8: null,
    textoResposta9: null,
    textoResposta10: null,
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 3,
    numeroPergunta: 3,
    pergunta: "QUAL É A SUA RENDA FAMILIAR?",
    quantasRespostasValidas: 1,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "até três mil reais",
    textoResposta2: "de três mil a cinco mil reais",
    textoResposta3: "de seis mil a dez mil reais",
    textoResposta4: "mais de dez mil reais",
    textoResposta5: null,
    textoResposta6: null,
    textoResposta7: null,
    textoResposta8: null,
    textoResposta9: null,
    textoResposta10: null,
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 4,
    numeroPergunta: 4,
    pergunta: "QUAL É A SUA ESCOLARIDADE?",
    quantasRespostasValidas: 1,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "até o ensino médio completo",
    textoResposta2: "graduação",
    textoResposta3: "especialização",
    textoResposta4: "mestrado",
    textoResposta5: "doutorado",
    textoResposta6: "pós-doutorado",
    textoResposta7: null,
    textoResposta8: null,
    textoResposta9: null,
    textoResposta10: null,
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 5,
    numeroPergunta: 5,
    pergunta: "QUANTOS LIVROS VOCÊ LEU NA VIDA?",
    quantasRespostasValidas: 1,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "0 a 10",
    textoResposta2: "11 a 20",
    textoResposta3: "21 a 30",
    textoResposta4: "31 a 50",
    textoResposta5: "51 a 100",
    textoResposta6: "101 a 500",
    textoResposta7: "501 a 1000",
    textoResposta8: "mais de 1000",
    textoResposta9: null,
    textoResposta10: null,
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 6,
    numeroPergunta: 6,
    pergunta:
      "QUE TIPOS DE LIVROS PREFERE? \r\n                Marque no máximo 03 (três)\r\n                ",
    quantasRespostasValidas: 3,
    tipoPergunta: "Peso",
    textoResposta1: "Poesia",
    textoResposta2: "Romance",
    textoResposta3: "Contos ou crônicas",
    textoResposta4: "Crítica literária",
    textoResposta5: "Biografia",
    textoResposta6: "Teóricos em geral",
    textoResposta7: "Filosofia",
    textoResposta8: "Psicologia",
    textoResposta9: "Autoajuda",
    textoResposta10: "Religiosos",
    textoResposta11: "Esotéricos e místicos",
    textoResposta12: "HQs",
    textoResposta13: "outros",
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 7,
    numeroPergunta: 7,
    pergunta:
      "QUAIS SÃO SEUS TEMAS FAVORITOS?\r\n                Marque no máximo 03 (três)\r\n                ",
    quantasRespostasValidas: 3,
    tipoPergunta: "Peso",
    textoResposta1: "suspense",
    textoResposta2: "terror",
    textoResposta3: "romantismo",
    textoResposta4: "erotismo",
    textoResposta5: "família e relacionamentos",
    textoResposta6: "artes",
    textoResposta7: "ficção científica",
    textoResposta8: "humor",
    textoResposta9: "ciência",
    textoResposta10: "tecnologia",
    textoResposta11: "empreendedorismo",
    textoResposta12: "história",
    textoResposta13: "política",
    textoResposta14: "economia",
    textoResposta15: "outros",
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 8,
    numeroPergunta: 8,
    pergunta: "VOCÊ PREFERE QUAL TIPO DE LITERATURA?",
    quantasRespostasValidas: 1,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "africana",
    textoResposta2: "alemã",
    textoResposta3: "brasileira",
    textoResposta4: "chinesa",
    textoResposta5: "espanhola",
    textoResposta6: "francesa",
    textoResposta7: "indígena",
    textoResposta8: "inglesa",
    textoResposta9: "italiana",
    textoResposta10: "japonesa",
    textoResposta11: "latino-americana",
    textoResposta12: "norte-americana",
    textoResposta13: "russa",
    textoResposta14: "do oriente médio",
    textoResposta15: "asiática em geral",
    textoResposta16: "não tem preferência",
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 9,
    numeroPergunta: 9,
    pergunta:
      "QUAIS MÍDIAS VOCÊ USA MAIS?\r\n                Marque com os números 1, 2, 3 as suas três favoritas, na ordem de preferência (1ª, 2ª e 3ª)\r\n                ",
    quantasRespostasValidas: 3,
    tipoPergunta: "Peso",
    textoResposta1: "jornais impressos",
    textoResposta2: "revistas impressas",
    textoResposta3: "televisão",
    textoResposta4: "rádio",
    textoResposta5: "internet em geral",
    textoResposta6: "sites de notícias",
    textoResposta7: "blogs, páginas, sites etc. de entretenimento",
    textoResposta8: "podcasts",
    textoResposta9: "streaming (Netflix, HBO, Amazon etc.)",
    textoResposta10: null,
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 10,
    numeroPergunta: 10,
    pergunta:
      "QUAL REDE SOCIAL/PLATAFORMA VOCÊ USA MAIS?\r\n                Marque com os números 1, 2, 3 as suas três favoritas, na ordem de preferência (1ª, 2ª e 3ª)\r\n                ",
    quantasRespostasValidas: 3,
    tipoPergunta: "Peso",
    textoResposta1: "Facebook",
    textoResposta2: "Instagram",
    textoResposta3: "YouTube",
    textoResposta4: "WhatsApp",
    textoResposta5: "Telegram",
    textoResposta6: "Twitter",
    textoResposta7: "TikTok",
    textoResposta8: "outros",
    textoResposta9: null,
    textoResposta10: null,
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 11,
    numeroPergunta: 11,
    pergunta:
      "VOCÊ SE DESCREVE COMO UMA PESSOA:\r\n                Marque quantas alternativas quiser, desde que indiquem estados predominantes em você.\r\n                ",
    quantasRespostasValidas: 0,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "alegre e resolvida",
    textoResposta2: "alegre e confusa",
    textoResposta3: "alternando entre alegria e tristeza frequentemente",
    textoResposta4: "melancólica",
    textoResposta5: "deprimida",
    textoResposta6: "seletiva",
    textoResposta7: "desconfiada",
    textoResposta8: "entediada",
    textoResposta9: "desmotivada",
    textoResposta10: "entusiasmada",
    textoResposta11: "alienada",
    textoResposta12: "engajada e ativista",
    textoResposta13: "individualista",
    textoResposta14: "coletivista",
    textoResposta15: "solitária e abandonada",
    textoResposta16: "solitária por opção",
    textoResposta17: "amorosa e/ou apaixonada",
    textoResposta18: "caseira",
    textoResposta19: "festeira, passeadora, viajante",
    textoResposta20: "viciada em trabalho",
    textoResposta21: "preguiçosa",
    textoResposta22: "equilibrada entre trabalho e lazer",
    textoResposta23: "focada",
    textoResposta24: "desatenta",
    textoResposta25: "ansiosa",
    textoResposta26: "hiperativa",
    textoResposta27: "assustada",
    textoResposta28: "prefere animais e/ou a natureza a pessoas",
    textoResposta29: "gosta tanto das pessoas quanto dos animais e da natureza",
    textoResposta30: "não pensa se prefere pessoas, animais ou natureza",
    textoResposta31: "acredita que o ser humano é essencialmente bom",
    textoResposta32: "acredita que o ser humano é essencialmente mau",
    textoResposta33:
      "acredita que o ser humano pode ser bom ou mau dependendo do contexto e de sua história de vida",
    textoResposta34: "estudiosa",
    textoResposta35: "cheia de fé",
    textoResposta36: "descrente",
    textoResposta37: "religiosa",
    textoResposta38: "espiritualista",
    textoResposta39: "ateia",
    textoResposta40: "agnóstica",
    textoResposta41: "livre-pensadora",
    textoResposta42: "culta",
    textoResposta43: "hipster",
    textoResposta44: "odeia rótulos",
    textoResposta45:
      "diria que odeia rótulos, mas considera a palavra “odiar” um pouco exagerada",
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 12,
    numeroPergunta: 12,
    pergunta:
      "EM QUAIS ASPECTOS DE SUA VIDA VOCÊ SE SENTE MENOS FELIZ?\r\n                Marque com os números 1, 2, 3 os problemas que mais lhe incomodam, na ordem de importância (1°, 2° e 3°).\r\n                ",
    quantasRespostasValidas: 3,
    tipoPergunta: "Peso",
    textoResposta1: "relacionamentos amorosos",
    textoResposta2: "relacionamentos sociais",
    textoResposta3: "relacionamentos familiares",
    textoResposta4: "trabalho e profissão",
    textoResposta5: "situação financeira",
    textoResposta6: "estudos e formação",
    textoResposta7: "saúde",
    textoResposta8: "lazer e entretenimento",
    textoResposta9: "questões existenciais, psicológicas e espirituais",
    textoResposta10: "questões sociais, políticas, econômicas e ambientais ",
    textoResposta11: "autoamor e autoconhecimento",
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 13,
    numeroPergunta: 13,
    pergunta:
      "LER UM OU MAIS LIVROS INDICADOS ESPECIALMENTE PARA VOCÊ PODE AJUDAR EM CADA UM DESSES PROBLEMAS POR QUÊ?\r\n                Marque com os números 1, 2, 3, 4 e 5 as suas cinco respostas favoritas, na ordem de preferência (1ª, 2ª, 3ª, 4ª e 5ª)\r\n                ",
    quantasRespostasValidas: 5,
    tipoPergunta: "Peso",
    textoResposta1:
      "Foi(foram)  pensado(s) por alguém que sabe ler meus sinais.",
    textoResposta2: "Foi(foram) pensado(s) exclusivamente para mim.",
    textoResposta3: "Todo livro bom pode ajudar qualquer pessoa.",
    textoResposta4:
      "Foi um ser humano capaz de empatia que o(s) pensou e sentiu para o meu caso em especial.",
    textoResposta5:
      "A linguagem, a forma, as personagens, os temas e os acontecimentos dele(s) têm muito a ver comigo, são meus semelhantes e posso me espelhar neles para resolver meus problemas.",
    textoResposta6:
      "A linguagem, a forma, as personagens, os temas e os acontecimentos dele(s) são muito diferentes de mim e podem me oferecer um ponto de vista e soluções em que eu jamais pensaria sozinha(o).",
    textoResposta7:
      "Esse(s) livro(s) vai(vão) dialogar com meus segredos mais secretos e me mostrar eu mesma(o) de um modo que eu nem suspeitava.",
    textoResposta8:
      "Eu permiti que o serviço de consultoria me lesse e identificasse meus sinais para me indicar o(s) livro(s) mais adequado(s) aos problemas que listei.",
    textoResposta9:
      "Esse(s) livro(s) foi(foram) pensado(s) e sentido(s) para mim com cuidados, amor e carinho.",
    textoResposta10:
      "Não sei ao certo porque, mas quero acreditar que sim, porque estou curioso e pode ser divertido.",
    textoResposta11: null,
    textoResposta12: null,
    textoResposta13: null,
    textoResposta14: null,
    textoResposta15: null,
    textoResposta16: null,
    textoResposta17: null,
    textoResposta18: null,
    textoResposta19: null,
    textoResposta20: null,
    textoResposta21: null,
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 14,
    numeroPergunta: 14,
    pergunta:
      "A QUAIS DESTES SERIADOS FAMOSOS VOCÊ ASSISTIU (MESMO SE PARCIALMENTE)?",
    quantasRespostasValidas: 0,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "13th reasons why",
    textoResposta2: "Anne with an e",
    textoResposta3: "Black Mirror",
    textoResposta4: "Dark",
    textoResposta5: "Friends",
    textoResposta6: "Games of Thrones",
    textoResposta7: "Grey’s Anatomy",
    textoResposta8: "House of Cards",
    textoResposta9: "La Casa de Papel",
    textoResposta10: "Lost",
    textoResposta11: "Messiah",
    textoResposta12: "Perdidos no espaço",
    textoResposta13: "Sense8",
    textoResposta14: "Star Trek",
    textoResposta15: "Star Wars",
    textoResposta16: "Strangers Things",
    textoResposta17: "The Big Bang Theory",
    textoResposta18: "The O.A.",
    textoResposta19: "Two and a half man",
    textoResposta20: "Nenhum desses seriados",
    textoResposta21: "Não assiste a seriados",
    textoResposta22: null,
    textoResposta23: null,
    textoResposta24: null,
    textoResposta25: null,
    textoResposta26: null,
    textoResposta27: null,
    textoResposta28: null,
    textoResposta29: null,
    textoResposta30: null,
    textoResposta31: null,
    textoResposta32: null,
    textoResposta33: null,
    textoResposta34: null,
    textoResposta35: null,
    textoResposta36: null,
    textoResposta37: null,
    textoResposta38: null,
    textoResposta39: null,
    textoResposta40: null,
    textoResposta41: null,
    textoResposta42: null,
    textoResposta43: null,
    textoResposta44: null,
    textoResposta45: null,
    textoResposta46: null,
    textoResposta47: null,
    textoResposta48: null,
    textoResposta49: null,
    textoResposta50: null,
  },
  {
    idPergunta: 15,
    numeroPergunta: 15,
    pergunta: "A QUAIS DESTES FILMES VOCÊ ASSISTIU?",
    quantasRespostasValidas: 0,
    tipoPergunta: "Múltipla Escolha",
    textoResposta1: "2001: uma odisseia no espaço",
    textoResposta2: "A árvore da vida",
    textoResposta3: "A chegada",
    textoResposta4: "A forma da água",
    textoResposta5: "A garota dinamarquesa",
    textoResposta6: "A invenção de Hugo Cabret",
    textoResposta7: "A morte em Veneza",
    textoResposta8: "A origem",
    textoResposta9: "A teoria de tudo",
    textoResposta10: "Amadeus",
    textoResposta11: "Apocalipse Now",
    textoResposta12: "Aquarius",
    textoResposta13: "As aventuras de Pi",
    textoResposta14: "Avatar",
    textoResposta15: "Bacurau",
    textoResposta16: "Bastardos inglórios",
    textoResposta17: "Blade Runner: o caçador de androides",
    textoResposta18: "Bohemian Rhapsody",
    textoResposta19: "Casanova",
    textoResposta20: "Central do Brasil",
    textoResposta21: "Cidade de Deus",
    textoResposta22: "Coringa",
    textoResposta23: "Deus e o diabo na terra do sol",
    textoResposta24: "Deuses americanos",
    textoResposta25: "Drácula de Bram Stocker",
    textoResposta26: "Duna",
    textoResposta27: "Entrevista com o vampiro",
    textoResposta28: "Godzilla versus Kong",
    textoResposta29: "Gravidade",
    textoResposta30: "Gritos e sussurros",
    textoResposta31: "Harry Potter (apenas um, mais de um ou todos)",
    textoResposta32: "História de um casamento",
    textoResposta33: "Interestelar",
    textoResposta34: "La dolce vita",
    textoResposta35: "Me chame pelo seu nome",
    textoResposta36: "Mortal Kombat",
    textoResposta37: "Não olhe para cima",
    textoResposta38: "No ritmo do coração",
    textoResposta39: "Nomadland",
    textoResposta40: "Nosferatu",
    textoResposta41: "O apartamento",
    textoResposta42: "O artista",
    textoResposta43: "O curioso caso de Benjamin Button",
    textoResposta44: "O discurso do rei",
    textoResposta45: "O filho de Saul",
    textoResposta46: "O grande hotel Budapeste",
    textoResposta47: "O lobo de Wall Street",
    textoResposta48: "O poço",
    textoResposta49: "O poderoso chefão",
    textoResposta50: "O senhor dos anéis (apenas um, mais de um ou todos",
  },
];

function createURLThatReturns() {
  const json = JSON.stringify(mockQuestionario);
  const blob = new Blob([json], { type: "application/json" });

  return URL.createObjectURL(blob);
}

// export const urlQuestionario = createURLThatReturns();
