import { string } from "prop-types";

// export var urlCorreios =
//   "https://localhost:44390/api/Correios/ObterValorPrazoFrete";
export var urlCorreios =
  "https://api.leituratua.com.br/api/Correios/ObterValorPrazoFrete";
// export var urlPessoas = "https://localhost:44390/api/Pessoas/";
export const urlPessoas = "https://api.leituratua.com.br/api/Pessoas/";

export var planoEscolhidoPeriodo = 1;
export var planoEscolhidoTipo = 3;
export var valorPlanoEscolhido = 0;
export var periodoEscolhido = 0;
export var token = "";
export var entregarEmailLogado = "";
export var entregarIdLogado = 1;
export var entregarPessoaLogada = {};
export var frete = 0;

export const pegarPlanoEscolhidoPeriodo = function (periodo) {
  planoEscolhidoPeriodo = periodo;
};

export const calculoFrete = function (cep) {
  fetch(urlCorreios, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      tipoServico: "c",
      cepOrigem: "03210001",
      cepDestino: "15025080",
      peso: "2",
      largur: "25",
      altura: "18",
      comprimento: "5",
      tipoObjeto: "c",
    }),
  })
    .then((resposta) => resposta.json())
    .then((resultado) => {
      frete = Number(resultado.preco.pcFinal.replace(/[,]/g, ".")) + 5;
      console.log(resultado.preco.pcFinal);
      console.log(frete);
    })
    .catch((err) => {
      console.log(err);
    });

  console.log("foi");
};

export const pegarValorFrete = function (valor) {
  frete = valor;
};

export const pegarPlanoEscolhidoTipo = function (tipo) {
  planoEscolhidoTipo = tipo;
};

export const planoEscolhido = function (periodo, tipo) {
  planoEscolhidoPeriodo = periodo;
  planoEscolhidoTipo = tipo;
};

export const entregarValorEPeriodoPlanoEscolhido = function (valor, periodo) {
  valorPlanoEscolhido = valor;
  periodoEscolhido = periodo;
};

export const idAtualLogado = function (id, pessoa) {
  entregarIdLogado = id;
  entregarPessoaLogada = pessoa;
  console.log(entregarIdLogado);
  console.log(pessoa);
};

export const pegarToken = function (tokenRecuperado) {
  token = tokenRecuperado;
  sessionStorage.setItem("token", tokenRecuperado);
};

export const idEEmailAtualLogado = function (id, email, pessoa) {
  entregarIdLogado = id;
  entregarEmailLogado = email;
  entregarPessoaLogada = pessoa;
  console.log(pessoa);
  console.log(id);
};

export const emailLogado = function (email) {
  entregarEmailLogado = email;
};

export const enderecoModel = {
  tipoEndereco: Number,
  cep: string,
  rua: string,
  numero: Number,
  complemento: String,
  bairro: String,
  cidade: String,
  uf: String,
  pessoaId: Number,
};

export const pessoaModel = {
  email: string,
  senha: String,
  ativo: Boolean,
  tipoPessoa: Number,
  cadastraUsuario: Boolean,
  cadastraPlano: Boolean,
  nome: string,
  cpf: string,
  dataNasimento: string,
  telefone: string,
  emailContato: string,
  enderecos: enderecoModel,
};

// export function logar(email, senha){
//     fetch(urlPessoas+"Login",{
//         method: 'POST',
//         headers: {
//             'Content-Type': 'application/json'
//         },
//         body: JSON.stringify({
//             "email": email,
//             "senha": senha,
//         }),
//     })
//     .then((resposta) => resposta.json())
//     .then((resultadoJson) => {
//         entregarPessoaLogada = resultadoJson;
//         console.log(resultadoJson)
//         return resultadoJson
//     })
//     .catch(err => {
//         return "Erro ao se conectar ao servidor: " + err;
//     });
// }

//    export const logar2(email, senha){
//        return new Promisse(res => {fetch(urlPessoas+"Login",{
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json'
//             },
//             body: JSON.stringify({
//                 "email": email,
//                 "senha": senha,
//             }),
//         })
//         .then((resposta) => resposta.json())
//         .then((resultadoJson) => {
//             console.log(resultadoJson)
//             entregarPessoaLogada = resultadoJson;
//             res(resultadoJson)
//         })
//         .catch(err => {
//             res("Erro ao se conectar ao servidor: " + err);
//         }); })
//     }
