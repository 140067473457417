import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./indexHeader.css";
import Login from "../../pages/login";
import { HashLink as Link } from "react-router-hash-link";
//import 'react-bootstrap';
import modalImgPessoa from "../../img/modalImgPessoa.png";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
//import 'bootstrap/dist/css/bootstrap.min.css';
import Image from "react-bootstrap/Image";
import Logo from "../../img/LeituraTua_-_colorido.png";
import ModalCadastro from "../../pages/home/modalCadastro.js";
import { Divider } from "@material-ui/core";
import * as modalService from "../../servicos/modal-service.js";

function Header() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showCadastro, setShowCadastro] = useState(false);
  const handleCloseCadastro = () => setShowCadastro(false);
  const handleShowCadastro = () => setShowCadastro(true);

  return (
    <Container fluid id="menu">
      <Row id="menuContainer">
        <Col xs={4} md={2} id="imgLogo">
          <Link to="/Home">
            <Image
              id="imagemLogoHeader"
              src={Logo}
              width="127"
              height="37"
              fluid
            />
          </Link>
        </Col>
        <Col md={6} id="menuAlternativoGrande">
          <Container fluid id="navMenu1">
            <ul>
              <li>
                <Link id="menuLink" to="/Home#comoFuncionaInteiro">
                  Como funciona
                </Link>
              </li>
              <li>
                <Link id="menuLink" to="/DetalhesPlanos">
                  Planos
                </Link>
              </li>
              <li>
                <Link id="menuLink" to="/Duvidas">
                  Dúvidas
                </Link>
              </li>
            </ul>
          </Container>
        </Col>
        <Col xs={5} md={3} id="navMenuDeLoguin">
          <Container fluid id="navMenu2">
            <button
              className="assinarModal"
              onClick={() => modalService.abrirModalCadastro()}
            >
              assinar
            </button>
            <button
              className="loginModal"
              onClick={() => modalService.abrirModalLoguin()}
            >
              logar
            </button>
          </Container>
        </Col>
      </Row>
      <Container id="menuAlternativoPequeno">
        <Container fluid id="navMenu1Alternativo">
          <li>
            <Link to="#comoFuncionaInteiro">Como funciona</Link>
          </li>
          <li>
            <Link to="/DetalhesPlanos">Planos</Link>
          </li>
          <li>
            <Link to="/Duvidas">Dúvidas</Link>
          </li>
        </Container>
      </Container>
      <ModalCadastro />
      <Login className="modal-form" />
    </Container>
  );
}

export default Header;
