import React from "react";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HashLink as Link } from "react-router-hash-link";
import Image from "react-bootstrap/Image";
import Cartoes from "../../img/cartoes2x.png";

function Footer2Versao() {
  return (
    <Container fluid id="rodape">
      <Container fluid id="inteiroFooter2">
        <Container fluid id="menuFooter2">
          <Row id="menuFooter2">
            <img
              id="pagBankGrande"
              src="//assets.pagseguro.com.br/ps-integration-assets/banners/divulgacao/468X60_10X_pagseguro.gif"
              alt="Banner PagSeguro"
              title="Parcele suas compras em até 18x"
            ></img>

            <img
              id="pagBankPequeno"
              src="//assets.pagseguro.com.br/ps-integration-assets/banners/divulgacao/120x240_10X_pagseguro.gif"
              alt="Banner PagSeguro"
              title="Parcele suas compras em até 18x"
            ></img>
            {/* <Image
                src={Cartoes}
                width="272"
                height="95"
                id="imgCartoes"
                fluid
              /> */}

            {/* <Col xs={3} md={4} id="colMenu2">
              <h6 id="footer2TextoSeguro">Ambiente 100% seguro</h6>
            </Col> */}
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default Footer2Versao;
