import React from "react";
import "./App.css";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import QuemSomos from "./pages/quemSomos";
import PoliticasPrivacidade from "./pages/politicasDePrivacidade";
import TermosDeUso from "./pages/termosDeUso";
import Cadastro from "./pages/cadastro";
import cadastroDadosPessoais from "./pages/cadastro/dadosPessoais.js";
import cadastroEndereco from "./pages/cadastro/endereco.js";
import Login from "./pages/login";
import PainelUsuario from "./pages/painelUsuario";
import PainelCLiente from "./pages/painelCliente";
import Questionario from "./pages/cadastro/questionario";
import Pagamento from "./pages/cadastro/pagamento";
import Obrigado from "./pages/cadastro/obrigado";
import Brindes from "./pages/brindes";
import Duvidas from "./pages/duvidas";
import DetalhesPlanos from "./pages/detalhesPlanos";
import SeusLivrosPessoais from "./pages/seusLivrosPessoais";
import TextoSobreVoce from "./pages/textoSobreVoce";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/Google"
        component={() => {
          window.location.href = "https://www.google.com.br/";
          return null;
        }}
      />

      <Route path="/Questionario" component={Questionario} />
      <Route path="/Pagamento" component={Pagamento} />
      <Route path="/Obrigado" component={Obrigado} />
      <Route path="/Cadastro" component={Cadastro} />
      <Route path="/cadastroDadosPessoais" component={cadastroDadosPessoais} />
      <Route path="/cadastroEndereco" component={cadastroEndereco} />
      <Route
        path="/TermosDeUso"
        component={() => {
          window.location.href =
            "https://www.leituratua.com.br/documentos/Contrato+com+clientes+da+LeituraTua.pdf";
        }}
      />
      <Route
        path="/PoliticasDePrivacidade"
        component={() => {
          window.location.href =
            "https://www.leituratua.com.br/documentos/Pol%C3%ADtica+de+privacidade+da+empresa+LeituraTua.pdf";
        }}
      />
      <Route path="/QuemSomos" component={QuemSomos} />
      <Route path="/Brindes" component={Brindes} />
      <Route path="/Duvidas" component={Duvidas} />
      <Route path="/SeusLivrosPessoais" component={SeusLivrosPessoais} />
      <Route path="/TextoSobreVoce" component={TextoSobreVoce} />
      <Route path="/DetalhesPlanos" component={DetalhesPlanos} />
      <Route path="/PainelDeUsuario" component={PainelUsuario} />
      <Route path="/PainelDeCLiente" component={PainelCLiente} />
      <Route path="/Login" component={Login} />
      <Route path="/" component={Home} />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
