import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Footer from "../../components/footer2Versao";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuCadastro from "./menuPlanosCadastro";
import Image from "react-bootstrap/Image";
import Logo from "../../img/logo2x.png";
import { HashLink as Link } from "react-router-hash-link";
import ViaCep from "react-via-cep";
import {
  urlPessoas,
  entregarIdLogado,
  entregarEmailLogado,
  token,
  calculoFrete,
} from "../../servicos/pessoas-service";
import swal from "sweetalert";
import { Redirect } from "react-router";
import InputMask from "react-input-mask";
import imgBuscaCep from "../../img/iconCep.png";

class cadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      controleEscondido: false,
      redirecionar: false,
      entregaDiferente: false,
    };

    this.entregaDiferente = this.entregaDiferente.bind(this);
    this.cadastrarForm = this.cadastrarForm.bind(this);
    this.buscarCep = this.buscarCep.bind(this);
    this.buscarCepEntrega = this.buscarCepEntrega.bind(this);
    this.incluirClassBotao = this.incluirClassBotao.bind(this);

    this.incluirClassBotao();
  }

  cadastrarForm(event) {
    let cep = document.getElementById("formCadastroEndereco").cepInput.value;
    let rua = document.getElementById("formCadastroEndereco").ruaInput.value;
    let numero = document.getElementById("formCadastroEndereco").numInput.value;
    let bairro = document.getElementById("formCadastroEndereco").bairroInput
      .value;
    let cidade = document.getElementById("formCadastroEndereco").cidadeInput
      .value;
    let uf = document.getElementById("formCadastroEndereco").ufInput.value;
    let complemento = document.getElementById("formCadastroEndereco")
      .complementoInput.value;

    let cepEntrega = "";
    let ruaEntrega = "";
    let numeroEntrega = "";
    let bairroEntrega = "";
    let cidadeEntrega = "";
    let ufEntrega = "";
    let complementoEntrega = "";

    if (this.state.entregaDiferente) {
      cepEntrega = document.getElementById("formCadastroEntrega").cepInput
        .value;
      ruaEntrega = document.getElementById("formCadastroEntrega").ruaInput
        .value;
      numeroEntrega = document.getElementById("formCadastroEntrega").numInput
        .value;
      bairroEntrega = document.getElementById("formCadastroEntrega").bairroInput
        .value;
      cidadeEntrega = document.getElementById("formCadastroEntrega").cidadeInput
        .value;
      ufEntrega = document.getElementById("formCadastroEntrega").ufInput.value;
      complementoEntrega = document.getElementById("formCadastroEntrega")
        .complementoInput.value;
    } else {
      cepEntrega = document.getElementById("formCadastroEndereco").cepInput
        .value;
      ruaEntrega = document.getElementById("formCadastroEndereco").ruaInput
        .value;
      numeroEntrega = document.getElementById("formCadastroEndereco").numInput
        .value;
      bairroEntrega = document.getElementById("formCadastroEndereco")
        .bairroInput.value;
      cidadeEntrega = document.getElementById("formCadastroEndereco")
        .cidadeInput.value;
      ufEntrega = document.getElementById("formCadastroEndereco").ufInput.value;
      complementoEntrega = document.getElementById("formCadastroEndereco")
        .complementoInput.value;
    }

    fetch(urlPessoas + "Cliente/CadastrarEnderecoResidencial", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        pessoaId: entregarIdLogado,
        cep: cep,
        rua: rua,
        numero: numero.slice(0, numero.indexOf("_")),
        bairro: bairro,
        cidade: cidade,
        complemento: complemento,
        uf: uf,
      }),
    })
      .then((resposta) => resposta.json())
      .then((resultado) => {
        if (resultado.mensagem) {
          swal(resultado.mensagem);
        } else {
          fetch(urlPessoas + "Cliente/CadastrarEnderecoEntrega", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
              pessoaId: entregarIdLogado,
              cep: cepEntrega,
              rua: ruaEntrega,
              numero: numeroEntrega.slice(0, numeroEntrega.indexOf("_")),
              bairro: bairroEntrega,
              cidade: cidadeEntrega,
              complemento: complementoEntrega,
              uf: ufEntrega,
            }),
          })
            .then((resposta) => resposta.json())
            .then((resultado) => {
              if (resultado.mensagem) {
                swal(resultado.mensagem);
              } else {
                calculoFrete(cepEntrega);
                this.setState({ redirecionar: true });
              }
            })
            .catch((err) => {
              console.log("Erro ao se conectar ao servidor: " + err);
            });
        }
      })
      .catch((err) => {
        console.log("Erro ao se conectar ao servidor: " + err);
      });

    event.preventDefault();
  }

  buscarCep(event) {
    let cep = document.getElementById("formCadastroEndereco").cepInput.value;
    let rua = document.getElementById("formCadastroEndereco").ruaInput;
    let bairro = document.getElementById("formCadastroEndereco").bairroInput;
    let cidade = document.getElementById("formCadastroEndereco").cidadeInput;
    let uf = document.getElementById("formCadastroEndereco").ufInput;

    if (cep == "") {
      swal("O cep não pode ser nulo.");
    } else
      fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: "GET",
      })
        .then((resposta) => resposta.json())
        .then((resultado) => {
          rua.value = resultado.logradouro;
          bairro.value = resultado.bairro;
          cidade.value = resultado.localidade;
          uf.value = resultado.uf;
        })
        .catch((err) => {
          console.log("Erro ao se conectar ao servidor: " + err);
        });
    event.preventDefault();
  }

  buscarCepEntrega(event) {
    let cep = document.getElementById("formCadastroEntrega").cepInput.value;
    let rua = document.getElementById("formCadastroEntrega").ruaInput;
    let bairro = document.getElementById("formCadastroEntrega").bairroInput;
    let cidade = document.getElementById("formCadastroEntrega").cidadeInput;
    let uf = document.getElementById("formCadastroEntrega").ufInput;

    if (cep == "") {
      swal("O cep não pode ser nulo.");
    } else
      fetch(`https://viacep.com.br/ws/${cep}/json/`, {
        method: "GET",
      })
        .then((resposta) => resposta.json())
        .then((resultado) => {
          rua.value = resultado.logradouro;
          bairro.value = resultado.bairro;
          cidade.value = resultado.localidade;
          uf.value = resultado.uf;
        })
        .catch((err) => {
          console.log("Erro ao se conectar ao servidor: " + err);
        });
    event.preventDefault();
  }

  entregaDiferente() {
    setTimeout(() => {
      const escondido = document.querySelector(".escondido");
      const escondidoHeader = document.querySelector(".escondidoHeader");
      this.setState({ entregaDiferente: true });

      if (!this.state.controleEscondido) {
        escondido.style.display = "flex";
        escondidoHeader.style.display = "flex";
        this.setState({ controleEscondido: true });
      } else {
        escondido.style.display = "none";
        escondidoHeader.style.display = "none";
        this.setState({ controleEscondido: false });
      }
    }, 200);
  }

  incluirClassBotao() {
    setTimeout(() => {
      const escondido = document.querySelector(".escondido");
      const escondidoHeader = document.querySelector(".escondidoHeader");
      const botao = document.getElementById("botaoCadastro");
      botao.classList.add("endereco");
      escondido.style.display = "none";
      escondidoHeader.style.display = "none";
    }, 200);
  }

  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/Questionario" />;
    }
    const corSetasSelecionadas = {
      backgroundColor: "#E6E7E8",
    };
    return (
      <Container fluid id="inteiro">
        <Container fluid id="cadastroInteiro">
          <Container fluid id="headerCadastro">
            <Row id="cadastroInteiro">
              <Col xs={6} md={4} id="headerCadastroEsquerda">
                <Link to="/">
                  <Image
                    src={Logo}
                    width="127"
                    height="37"
                    fluid
                    id="imgLogo2"
                  />
                </Link>
              </Col>
              <Col xs={12} md={8} id="headerCadastroDireita">
                <Row id="setas">
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec" />
                    <div id="setaInversaBaixoSelec" />
                    <div id="rectangleSelec">
                      <p id="escritoSetaPlanoSelec">passo 01</p>
                      <p id="escritoSetaDescricaoSelec">Planos</p>
                    </div>
                    <div id="setaSelec" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec2" />
                    <div id="setaInversaBaixoSelec2" />
                    <div id="rectangleSelec2">
                      <p id="escritoSetaPlanoSelec">passo 02</p>
                      <p id="escritoSetaDescricaoSelec">Dados</p>
                    </div>
                    <div id="setaSelec2" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec3" />
                    <div id="setaInversaBaixoSelec3" />
                    <div id="rectangleSelec3">
                      <p id="escritoSetaPlanoSelec">passo 03</p>
                      <p id="escritoSetaDescricaoSelec">Endereço</p>
                    </div>
                    <div id="setaSelec3" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectangle">
                      <p id="escritoSetaPlano">passo 04</p>
                      <p id="escritoSetaQuest">Questionário</p>
                    </div>
                    <div id="seta" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectangle">
                      <p id="escritoSetaPlano">passo 05</p>
                      <p id="escritoSetaDescricao">Pagamento</p>
                    </div>
                    <div id="seta" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectanglePonta">
                      <p id="escritoSetaObrigado">Obrigado!</p>
                    </div>
                  </Container>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid id="conteudoCadastroInteiro">
          <Container fluid id="conteudoCadastro">
            <Container fluid id="monteSeuPlano">
              03 - Seu endereço.
            </Container>
            <Container fluid id="menuMonteSeuPlano">
              <form action="" method="post" id="formCadastroEndereco">
                <Col xs={12} md={12} style={{ padding: "0" }}>
                  <label for="cepInput" id="formLabel">
                    CEP:
                  </label>
                  <InputMask
                    type="text"
                    mask="99999-999"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    name="cepInput"
                    id="formInput"
                    size="50"
                  />
                  <img
                    src={imgBuscaCep}
                    className="imgBuscaCep"
                    onClick={this.buscarCep}
                  ></img>
                </Col>
                <Col xs={12} md={12} style={{ padding: "0" }}>
                  <label for="ruaInput" id="formLabel">
                    Rua:
                  </label>
                  <input type="text" name="ruaInput" id="formInput" size="50" />
                </Col>
                <Col xs={12} md={12}>
                  <Container id="inputEsquerda" style={{ padding: "0" }}>
                    <label for="numInput" id="formLabel">
                      N°:
                    </label>
                    <InputMask
                      mask="99999999"
                      value={this.props.value}
                      onChange={this.props.onChange}
                      type="text"
                      name="numInput"
                      id="formInput"
                      size="8"
                      style={{ width: "30%", float: "none" }}
                    />
                  </Container>
                  <Container id="inputDireita">
                    <label
                      for="complementoInput"
                      id="formLabel"
                      style={{ margin: "0 0 0 60px" }}
                    >
                      Complemento:
                    </label>
                    <input
                      type="text"
                      id="formInput"
                      name="complementoInput"
                      className="inputDaDireita"
                      size="29"
                      style={{ width: "48%", float: "none" }}
                    />
                  </Container>
                </Col>
                <Col xs={12} md={12} style={{ padding: "0" }}>
                  <label for="bairroInput" id="formLabel">
                    Bairro:
                  </label>
                  <input
                    type="text"
                    id="formInput"
                    name="bairroInput"
                    size="48"
                  />
                </Col>
                <Col xs={12} md={12}>
                  <Container id="inputEsquerda" style={{ padding: "0" }}>
                    <label for="ufInput" id="formLabel">
                      UF:
                    </label>
                    <input
                      type="text"
                      id="formInput"
                      name="ufInput"
                      size="7"
                      style={{ width: "30%", float: "none" }}
                    />
                  </Container>
                  <Container id="inputDireita">
                    <label
                      for="cidadeInput"
                      id="formLabel"
                      style={{ margin: "0 0 0 80px" }}
                    >
                      Cidade:
                    </label>
                    <input
                      type="text"
                      id="formInput"
                      name="cidadeInput"
                      className="inputDaDireita"
                      size="36"
                      style={{ width: "56%", float: "none" }}
                    />
                  </Container>
                </Col>
                <Container
                  className="imputCheckbox"
                  style={{ marginTop: "15px", marginBottom: "-40px" }}
                >
                  <input
                    className="mainClienteImputCheckbox"
                    onChange={this.entregaDiferente}
                    type="checkbox"
                    name="mesmoEndereco"
                  />
                  <label className="mainClienteLabel">
                    <p>Endereço de entrega diferente</p>
                  </label>
                </Container>
              </form>
            </Container>
            <Container className="escondidoHeader" id="headerEntrega">
              Onde devemos mandar?.
            </Container>
            <Container fluid id="menuMonteSeuPlano" className="escondido">
              <form action="" method="post" id="formCadastroEntrega">
                <Col xs={12} md={12} style={{ padding: "0" }}>
                  <label for="cepInput" id="formLabel">
                    CEP:
                  </label>
                  <InputMask
                    type="text"
                    mask="99999-999"
                    value={this.props.value}
                    onChange={this.props.onChange}
                    name="cepInput"
                    id="formInput"
                    size="50"
                  />
                  <img
                    src={imgBuscaCep}
                    className="imgBuscaCep"
                    onClick={this.buscarCepEntrega}
                  ></img>
                </Col>
                <Col xs={12} md={12} style={{ padding: "0" }}>
                  <label for="ruaInput" id="formLabel">
                    Rua:
                  </label>
                  <input type="text" name="ruaInput" id="formInput" size="50" />
                </Col>
                <Col xs={12} md={12}>
                  <Container id="inputEsquerda" style={{ padding: "0" }}>
                    <label for="numInput" id="formLabel">
                      N°:
                    </label>
                    <InputMask
                      mask="99999999"
                      value={this.props.value}
                      onChange={this.props.onChange}
                      type="text"
                      name="numInput"
                      id="formInput"
                      size="8"
                      style={{ width: "30%", float: "none" }}
                    />
                  </Container>
                  <Container id="inputDireita">
                    <label
                      for="complementoInput"
                      id="formLabel"
                      style={{ margin: "0 0 0 60px" }}
                    >
                      Complemento:
                    </label>
                    <input
                      type="text"
                      id="formInput"
                      name="complementoInput"
                      className="inputDaDireita"
                      size="29"
                      style={{ width: "48%", float: "none" }}
                    />
                  </Container>
                </Col>
                <Col xs={12} md={12} style={{ padding: "0" }}>
                  <label for="bairroInput" id="formLabel">
                    Bairro:
                  </label>
                  <input
                    type="text"
                    name="bairroInput"
                    id="formInput"
                    size="48"
                  />
                </Col>
                <Col xs={12} md={12}>
                  <Container id="inputEsquerda" style={{ padding: "0" }}>
                    <label for="ufInput" id="formLabel">
                      UF:
                    </label>
                    <input
                      type="text"
                      name="ufInput"
                      id="formInput"
                      size="7"
                      style={{ width: "30%", float: "none" }}
                    />
                  </Container>
                  <Container id="inputDireita">
                    <label
                      for="cidadeInput"
                      id="formLabel"
                      style={{ margin: "0 0 0 80px" }}
                    >
                      Cidade:
                    </label>
                    <input
                      type="text"
                      id="formInput"
                      name="cidadeInput"
                      className="inputDaDireita"
                      size="36"
                      style={{ width: "56%", float: "none" }}
                    />
                  </Container>
                </Col>
              </form>
            </Container>

            <Container id="botaoCadastro">
              <ButtonGroup id="menuBotaoAssineMesesCadastro">
                <Button variant="primary" id="botaoCadastroVoltar">
                  <Link to="/cadastroDadosPessoais">
                    <h6>Voltar</h6>
                  </Link>
                </Button>
                <Button
                  variant="primary"
                  id="botaoCadastroAvancar"
                  onClick={this.cadastrarForm}
                >
                  <h6 id="textoBotaoAvancar">Próximo</h6>
                </Button>
              </ButtonGroup>
            </Container>
          </Container>
        </Container>
        <Footer />
      </Container>
    );
  }
}

export default cadastro;
