import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import "./index.css";
import texto1 from "../../img/img1tx2x.png";
import texto2 from "../../img/img2tx2x.png";
import liv1txt1 from "../../img/img1lv1tx2x.png";
import liv2txt2 from "../../img/img2liv2tx2x.png";
import {
  planoEscolhidoPeriodo,
  planoEscolhidoTipo,
} from "../../servicos/pessoas-service.js";

function ConteudoMenuCadastro({ botaoPeriodo, botaoLivro }) {
  const marginValor = {
    marginLeft: "40px",
  };
  const marginSaibaMais = {
    marginBottom: "-45px",
  };
  if (botaoPeriodo == 1 && botaoLivro == 1) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={texto1}
                id="img2"
                width="136"
                height="159"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 style={marginValor}>R$79,90</h1>
              <p id="freteCadastro"> + frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você recebe por mês, durante seis meses, personalizado e
              individual com indicação de 1 livro para ajudar a entender melhor
              suas angústias, seus problemas e celebrar suas alegrias e suas
              conquistas.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMaisCadastro">
              saiba mais
            </Link>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 1) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={texto1}
                id="img2"
                width="136"
                height="159"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 style={marginValor}>R$79,90</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você recebe por mês, durante seis meses, personalizado e
              individual com indicação de 1 livro para ajudar a entender melhor
              suas angústias, seus problemas e celebrar suas alegrias e suas
              conquistas.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMaisCadastro">
              saiba mais
            </Link>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 1) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={texto1}
                id="img2"
                width="136"
                height="159"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 style={marginValor}>R$160,00</h1>

              <p id="freteCadastro"> + frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 2) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={texto2}
                id="img2"
                width="177"
                height="216"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 id="valorCadastroH1">R$159,90</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você receberá em casa pontualmente um único kit de experiência
              biblioterapêutica, que inclui: o livro físico e a carta de análise
              personalizada e curadoria literária. O kit da LeituraTua é inédito
              e pensado para você para ajudar no enfrentamento de angústias e
              melhorar sua saúde mental com o poder da literatura.
            </h3>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 2) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={texto2}
                id="img2"
                width="177"
                height="216"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 id="valorCadastroH1">R$135,90</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você recebe por mês, durante seis meses, 2 textos personalizados e
              individuais com indicações de 2 livros para ajudar a entender
              melhor suas angústias, seus problemas e celebrar suas alegrias e
              suas conquistas.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMaisCadastro">
              saiba mais
            </Link>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 2) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={texto2}
                id="img2"
                width="177"
                height="216"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 id="valorCadastroH1">R$125,90</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 3) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={liv1txt1}
                id="img2"
                width="227"
                height="166"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 style={marginValor}>R$185,00</h1>
              <p id="freteCadastro"> + frete</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você receberá em casa pontualmente um único kit de experiência
              biblioterapêutica, que inclui: o livro físico e a carta de análise
              personalizada e curadoria literária. O kit da LeituraTua é inédito
              e pensado para você para ajudar no enfrentamento de angústias e
              melhorar sua saúde mental com o poder da literatura.
            </h3>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 3) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={liv1txt1}
                id="img2"
                width="227"
                height="166"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 style={marginValor}>R$185,00</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você recebe por mês, durante seis meses, 1 livro para ajudar a
              entender melhor suas angústias, seus problemas e celebrar suas
              alegrias e suas conquistas.
            </h3>
            <p id="mais3Cadastro">+</p>
            <h3 id="textoConteudoCadastro" style={marginSaibaMais}>
              explicativo personalizado e individual para seu caso.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMaisCadastro">
              saiba mais
            </Link>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 3) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={liv1txt1}
                id="img2"
                width="227"
                height="166"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 style={marginValor}>R$160,00</h1>
              <p id="freteCadastro"> + frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 4) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={liv2txt2}
                id="img2"
                width="233"
                height="200"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 id="valorCadastroH1">R$185,00</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você receberá em casa pontualmente um único kit de experiência
              biblioterapêutica, que inclui: o livro físico e a carta de análise
              personalizada e curadoria literária. O kit da LeituraTua é inédito
              e pensado para você para ajudar no enfrentamento de angústias e
              melhorar sua saúde mental com o poder da literatura.
            </h3>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 4) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={liv2txt2}
                id="img2"
                width="233"
                height="200"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 id="valorCadastroH1">R$175,90</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você recebe por mês, durante seis meses, 2 livros para ajudar a
              entender melhor suas angústias, seus problemas e celebrar suas
              alegrias e suas conquistas.
            </h3>
            <p id="mais3Cadastro">+</p>
            <h3 id="textoConteudoCadastro" style={marginSaibaMais}>
              2 textos explicativos personalizados e individuais para seu caso.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMaisCadastro">
              saiba mais
            </Link>
          </Container>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 4) {
    return (
      <Row id="planosConteudoCadastro">
        <Col xs={12} md={6} id="conteudo1Cadastro">
          <Row id="conteudoAssineCadastro">
            <Col>
              <Image
                src={liv2txt2}
                id="img2"
                width="233"
                height="200"
                id="imgPlanosCadastro"
              />
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={6} id="conteudo2Cadastro">
          <Container id="conteudoDescricaoCadastro">
            <Container id="valorCadastro">
              <h1 id="valorCadastroH1">R$165,90</h1>
              <p id="maisCadastro">+</p>
              <p id="freteCadastro">frete</p>
              <p id="mensaisCadastro">mensais</p>
            </Container>
            <h3 id="textoConteudoCadastro">
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
      </Row>
    );
  }
}

export default ConteudoMenuCadastro;
