import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ImgInteira from "../../img/pessoLendo.jpg";

function detalhesPlanos() {
  return (
    <Container fluid id="inteiro">
      <Header id="cabecalho" />
      <Container fluid id="imagem">
        <Image
          src={ImgInteira}
          id="img2"
          width="1281"
          height="397"
          className="imgCarrosel"
        />
      </Container>
      <Container fluid id="conteudoDetalhesPlanos">
        <h3>
          {" "}
          <p>Temos disponíveis o plano de Mensal e plano anual.</p>
          <p>
            O assinante deve optar pelo período de assinatura. Os kits serão
            enviados mensalmente, pela quantidade de meses escolhida de acordo
            com o plano, contendo livro(s) e as carta(s).
          </p>
          <Container id="mesUnico">
            <p>Plano de Mensal </p>
            <p>1 Kit com 1 livro e 1 carta personalizada.</p>
            {/* <p>
              1 Kit com 2 livros e 2 cartas personalizadas e brinde exclusivo.
            </p> */}
          </Container>
          <Container id="semestral">
            {/* <p>Plano semestral </p>
            <p>
              6 Kits com 1 livro e 1 carta personalizada e brinde exclusivo.
            </p> */}
            {/* <p>
              6 Kits com 2 livros e 2 cartas personalizada e brinde exclusivo.
            </p> */}
            {/* <p>Os kits serão enviados mensalmente ao longo de 6 meses.</p> */}
          </Container>
          <Container id="anual">
            <p>Plano anual </p>
            <p>12 Kits com 1 livro e 1 carta personalizada.</p>
            {/* <p>
              12 Kits com 2 livros e 2 cartas personalizada e brinde exclusivo.
            </p> */}
            <p>Os kits serão enviados mensalmente ao longo de 12 meses.</p>
          </Container>
        </h3>
      </Container>
      <Footer />
    </Container>
  );
}

export default detalhesPlanos;
