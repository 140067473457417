import React, { Component } from "react";
import "./painelUsuario.css";
import Container from "react-bootstrap/Container";
import {
  entregarIdLogado,
  entregarPessoaLogada,
  urlPessoas,
  token,
} from "../../servicos/pessoas-service.js";

class CartoesCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoaAtual: entregarPessoaLogada,
      idAtual: entregarIdLogado,
    };
  }

  // componentDidMount(){
  //     fetch(urlPessoas+"Cliente/Procurar",{
  //                 method: 'POST',
  //                 headers: {
  //                     'Content-Type': 'application/json',
  //                     'Authorization':`Bearer ${token}`
  //                 },
  //                 body: JSON.stringify({
  //                     "id": this.state.idAtual,
  //                     "nome": "string",
  //                     "dataNasimento": "string",
  //                     "telefone": "string",
  //                     "emailContato": this.state.emailAtual
  //                 }),
  //             })
  //             .then((resposta) => resposta.json())
  //             .then((resultadoJson) => {
  //                 this.setState({resultado: resultadoJson});
  //                 console.log(resultadoJson);

  //             })
  //             .catch(err => {
  //                 console.log("Erro ao se logar: " + err);
  //             });

  // }

  render() {
    return (
      <Container fluid className="inteiroMainCliente">
        <Container fluid className="mainCliente">
          <h1>Cartões</h1>
          <h3>{this.state.pessoaAtual.nome}</h3>
          <h3>{this.state.pessoaAtual.id}</h3>
          <h3>{this.state.pessoaAtual.email}</h3>
          <h3>{this.state.pessoaAtual.cpf}</h3>
          <h3>{this.state.pessoaAtual.senha}</h3>
        </Container>
      </Container>
    );
  }
}

export default CartoesCliente;
