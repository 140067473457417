import styled, { keyframes } from "styled-components";

const abrir = (p) => keyframes`
0% { height: 0; }
100% { height: ${p}; } `;

const fechar = (p) => keyframes`
0% { height: ${p}; }
100% { height: 0; } `;

export const Resposta = styled.p`
  color: #0098da;
  font-family: Helvetica, sans-serif;
  font-size: 20px;
  text-indent: 30px;
  text-align: justify;
  animation-name: ${(props) =>
    props.aberto == "aberto" ? fechar(props.tamanho) : abrir(props.tamanho)};
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  overflow: hidden;
  animation-play-state: ${(props) => props.pausado};
`;
