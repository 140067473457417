import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ImgInteira from "../../img/pessoLendo.jpg";

function textoSobreVoce() {
  return (
    <Container fluid id="inteiro">
      <Header id="cabecalho" />
      <Container fluid id="imagem">
        <Image
          src={ImgInteira}
          id="img2"
          width="1281"
          height="397"
          className="imgCarrosel"
        />
      </Container>
      <Container fluid id="conteudoTextoSobreVoce">
        <h3>
          O texto que acompanha cada <b>livro pessoal</b>, é um{" "}
          <b>texto sobre você</b>, <b>para você</b>, explicando porque estamos
          indicando aquele livro naquele momento,{" "}
          <b>
            a partir das suas respostas ao questionário e das relações de umas
            com as outras{" "}
          </b>
          e entre <b>elas e os livros</b>.
        </h3>
      </Container>
      <Footer />
    </Container>
  );
}

export default textoSobreVoce;
