import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ImgInteira from "../../img/pessoLendo.jpg";

function seusLivrosPessoais() {
  return (
    <Container fluid id="inteiro">
      <Header id="cabecalho" />
      <Container fluid id="imagem">
        <Image
          src={ImgInteira}
          id="img2"
          width="1281"
          height="397"
          className="imgCarrosel"
        />
      </Container>
      <Container fluid id="conteudoSeusLivrosPessoais">
        <h3>
          Os livros são ferramentas poderosas de autoconhecimento e conhecimento
          da aventura humana, principalmente quando <b>são para você</b>,
          <b> especialmente para você</b>, quando um ser humano que desenvolveu
          habilidades profissionais para usar a inteligência e o afeto ao
          escolher livros para outra pessoa, baseando-se nas respostas dessa
          pessoa a uma ferramenta elaborada com técnica e carinho, os indica{" "}
          <b>para o seu caso</b>. O resultado disso pode ser a relação entre
          obra e leitor trazendo mais bem-estar à existência desse leitor. Nossa
          missão é facilitar esse encontro, ajudando os associados a terem{" "}
          <b>mais vida, mais amor e mais criatividade</b>, a partir da leitura
          de obras indicadas de <b>modo personalizado </b>
          para cada um, <b>sem o uso de algoritmos</b>, a partir de suas
          respostas a um questionário que contém questões dissertativas{" "}
          <b>sobre você, escritas por você</b>.
        </h3>
      </Container>
      <Footer />
    </Container>
  );
}

export default seusLivrosPessoais;
