import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import Cookies from "../../components/cookies";
import { HashLink as Link } from "react-router-hash-link";
import ModalCadastro from "./modalCadastro";
import "./index.css";
import MenuPlanos from "./menuPlanos.js";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import CarImg1 from "../../img/carrosel_limpo1_direita.png";
import CarImg2 from "../../img/carrosel_limpo2_direita.png";
import CarImg3 from "../../img/carrosel_limpo3_direita.png";
import SeCadastre from "../../img/imgTxtSeCad2x.png";
import Plano from "../../img/imgEscPlano2x.png";
import Receba from "../../img/imgReceba2x.png";
import opOlavo from "../../img/opOlavo.jpg";
import opYuna from "../../img/opYuna.jpg";
import opDilma from "../../img/opDilma.jpg";
import { Redirect } from "react-router-dom";
import Login from "../../pages/login";

function Home() {
  const carroselIndicators = {
    backgroundColor: "#ffffff",
  };

  return (
    <Container fluid id="inteiro">
      <Header id="cabecalho" />

      <Container fluid id="carroselInteiro">
        <Row id="carrosel">
          <Col id="carrosel">
            <Carousel id="carrosel" style={carroselIndicators}>
              <Carousel.Item
                id="imgCarrosel_1"
                style={{ backgroundColor: "#8160ca" }}
              >
                <Image
                  src={CarImg1}
                  width="732"
                  height="397"
                  className="imgCarrosel"
                  style={{ float: "right" }}
                />
                <Carousel.Caption>
                  <h3 className="txtCarrosel1">
                    Tenha os <i>grandes escritores</i> como guias no seu
                    processo de autoconhecimento
                  </h3>
                  <div className="butonCarrosel1">
                    <h3>
                      {" "}
                      <Link
                        to="/QuemSomos#inteiro"
                        className="buttonCarroselLink"
                      >
                        SAIBA MAIS
                      </Link>
                    </h3>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item
                id="imgCarrosel_2"
                style={{ backgroundColor: "#eae0ba" }}
              >
                <Image
                  src={CarImg2}
                  width="357"
                  height="397"
                  className="imgCarrosel"
                  style={{ float: "right" }}
                />
                <Carousel.Caption>
                  <h3 className="txtCarrosel2">
                    <i>Não é terapia, mas é terapêutico</i>
                    <br />
                    Com a LeituraTua, os livros se tornam seus aliados na saúde
                    mental e no autocuidado.
                  </h3>
                  <div className="butonCarrosel2">
                    <h3>
                      <Link
                        to="/QuemSomos#conteudoParaQueServimos"
                        className="buttonCarroselLink"
                      >
                        CONHEÇA
                      </Link>
                    </h3>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>

              <Carousel.Item
                id="imgCarrosel_3"
                style={{
                  background:
                    "linear-gradient( 90deg, #7862c6 10%, #e08a81 90%)",
                }}
              >
                <Image
                  width="0"
                  height="397"
                  className="imgCarrosel"
                  style={{ float: "right" }}
                />
                <Carousel.Caption>
                  <div className="txtCarrosel3">
                    <h3 className="txtCarrosel31">
                      O LIVRO IDEAL, NO MOMENTO CERTO
                    </h3>
                    <p className="txtCarrosel32">
                      Uma verdadeira experiência literária personalizada para
                      você
                    </p>
                  </div>
                  <div className="butonCarrosel3">
                    <Link to="/DetalhesPlanos" className="buttonCarroselLink">
                      <h3>CONHEÇA NOSSOS PLANOS</h3>
                    </Link>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container>

      <Container fluid id="comoFuncionaInteiro">
        <Container fluid id="h1Enchimento" />
        <Container fluid id="comoFunciona">
          <Row>
            <Col>
              <Container fluid id="h1">
                <h2>Como funciona</h2>
              </Container>
              <Container id="nucleo">
                <Row>
                  <Col xs={6} md={4} id="nucleoComoFunciona">
                    <h4>Cadastre-se</h4>
                    <Image
                      src={SeCadastre}
                      width="107"
                      height="116"
                      className="imgComoFunciona"
                    />
                    <p>
                      Responda nosso questionário para conhecermos seu perfil de
                      leitor, seus interesses e seu momento psicológico.
                    </p>
                  </Col>
                  <Col xs={6} md={4} id="nucleoComoFunciona">
                    <h4>Encontre seu plano</h4>
                    <Image
                      src={Plano}
                      width="184"
                      height="125"
                      className="imgComoFunciona"
                    />
                    <p>
                      Analisaremos suas respostas em um serviço de
                      biblioterapia, por meio da qual um livro será indicado de
                      forma personalizada a você.
                    </p>
                  </Col>
                  <Col xs={6} md={4} id="nucleoComoFunciona">
                    <h4>Receba em casa</h4>
                    <Image
                      src={Receba}
                      width="81"
                      height="97"
                      className="imgComoFunciona"
                    />
                    <p>
                      Chegará na sua casa o kit de experiência
                      biblioterapêutica, com o livro, uma carta personalizada
                      com nossa análise e o motivo da obra ser recomendada a
                      você. A cada novo mês, um kit inédito em sua casa.
                    </p>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </Container>

      <MenuPlanos id="cadastro" />
      {/* 
      <Container fluid id="opnioesInteiro">
        <Container fluid id="h1Enchimento2" />
        <Container fluid id="comoFunciona">
          <Container fluid id="h12">
            <h2>Opiniões</h2>
          </Container>
          <Container id="nucleoOpnioes">
            <Container fluid id="carroselInteiroOpnioes">
              <Row id="carrosel">
                <Col id="carrosel">
                  <Carousel id="carrosel">
                    <Carousel.Item id="imgCarroselOp">
                      <Container className="imagemOpinioes">
                        <Image src={opYuna} id="imgCarrOpnioes" />
                      </Container>
                      <Carousel.Caption id="textoOpnioes">
                        <h3>Yuna Magalhães (Enchedora de fraldas)</h3>
                        <p>
                          ""Gliglagligla, gois e teis do texe, gligaglila miga,
                          gligagligagliga..."
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item id="imgCarroselOp">
                      <Container className="imagemOpinioes">
                        <Image src={opOlavo} id="imgCarrOpnioes" />
                      </Container>
                      <Carousel.Caption id="textoOpnioes">
                        <h3>Olavo de Carvalho (Astrólogo)</h3>
                        <p>
                          "Depois que assinei LeituraTua, mudei minha visão do
                          mundo. Hoje sou contra o armamento, esquerdista e
                          consigo entender que a terra é redonda.""
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item id="imgCarroselOp">
                      <Container className="imagemOpinioes">
                        <Image src={opDilma} id="imgCarrOpnioes" />
                      </Container>
                      <Carousel.Caption id="textoOpnioes">
                        <h3>Dilma Rousseff (PresidentA)</h3>
                        <p>
                          "Tudo o que sei é o que sei e o que não sei é por que
                          fiquei sem saber. O que ja foi dito foi dito, e o que
                          não foi digo ninguem ficou sabendo. Hoje eu que eu
                          leio mais graças a lt eu sei mais do que sabia ontem e
                          por isto digo mais para que as pessoas não fiquem mais
                          sem saber do que não foi dito."
                        </p>
                      </Carousel.Caption>
                    </Carousel.Item>
                  </Carousel>
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>
      </Container> */}

      <Footer />
      <Cookies />
    </Container>
  );
}

export default Home;
