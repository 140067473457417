import React, { useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ConteudoMenu from "./conteudoMenu.js";
import "./index.css";

function MenuPlanos() {
  const [botaoPeriodo, botaoClicadoPeriodo] = useState(1);
  const [botaoLivro, botaoClicadoLivro] = useState(3);

  var periodo = 1;
  var livros = 3;

  if (botaoPeriodo == 1) {
    periodo = 1;
  } else if (botaoPeriodo == 2) {
    periodo = 2;
  } else if (botaoPeriodo == 3) {
    periodo = 3;
  }
  if (botaoLivro == 1) {
    livros = 1;
  } else if (botaoLivro == 2) {
    livros = 2;
  } else if (botaoLivro == 3) {
    livros = 3;
  } else if (botaoLivro == 4) {
    livros = 4;
  }

  const butonStyle = {
    background: "#0098DA",
  };

  if (botaoPeriodo == 1 && botaoLivro == 1) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                    disabled
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos <p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoTexto">
                      <h1>1 Texto</h1>
                    </Col>
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Semestral</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 1) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                    disabled
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos <p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoTexto">
                      <h1>1 Texto</h1>
                    </Col>
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Semestral</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 1) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                    disabled
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  >
                    <p>+ 1 livro </p>
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros </p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoTexto">
                      <h1>1 Texto</h1>
                    </Col>
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Anual</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 2) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoTexto">
                      <h1>2 Textos</h1>
                    </Col>
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Mês Único</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 2) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoTexto">
                      <h1>2 Textos</h1>
                    </Col>
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Semestral</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 2) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoTexto">
                      <h1>2 Textos</h1>
                    </Col>
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Anual</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 3) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                    style={butonStyle}
                  >
                    Mensal
                  </Button>
                  {/* <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button> */}
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  >
                    
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Mensal</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 3) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                    disabled
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Semestral</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 3) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                  >
                    Mensal
                  </Button>
                  {/* <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button> */}
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  >
                    
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Anual</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 4) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Mês Único</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 4) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Semestral</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 4) {
    return (
      <Container fluid id="planosInteiro">
        <Container id="menuDireitaPreenchimento" />
        <Container id="PlanosConteudoInteiro"></Container>
        <Container fluid id="menuInteiroPreenchimento">
          <Row id="planosMenu">
            <Col xs={12} md={6} id="menuAssineLivros">
              <Container id="navAssine">
                <ButtonGroup id="menuAssineMeses">
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(1)}
                  >
                    Mensal
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(2)}
                  >
                    Semestral
                  </Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoAssine"
                    onClick={() => botaoClicadoPeriodo(3)}
                  >
                    Anual
                  </Button>
                </ButtonGroup>
                {/* <ButtonGroup id="menuAssineLivros">
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(1)}
                  ></Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(2)}
                  >
                    2 textos
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(3)}
                  ></Button>
                  <Button
                    variant="primary"
                    style={butonStyle}
                    id="botaoLivros"
                    onClick={() => botaoClicadoLivro(4)}
                  >
                    2 textos<p>+ 2 livros</p>
                  </Button>
                </ButtonGroup> */}
              </Container>
            </Col>

            <Col xs={12} md={6} id="menuAssineMeses">
              <Container id="menuDaDireitaPlanos">
                <Row className="row-planos">
                  <Col id="menuDireitaPlanos">
                    <h1>Planos</h1>
                  </Col>
                  <Col id="menuDireitaPeriodo">
                    <Col id="menuDireitaPeriodoPeriodo">
                      <h1>Anual</h1>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container fluid id="planosConteudo">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  }
}

export default MenuPlanos;
