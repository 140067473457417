import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Footer from "../../components/footer2Versao";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuCadastro from "./menuPlanosCadastro";
import Image from "react-bootstrap/Image";
import Logo from "../../img/logo2x.png";
import { HashLink as Link } from "react-router-hash-link";
import ViaCep from "react-via-cep";

class Obrigado extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container fluid id="inteiro">
        <Container fluid id="cadastroInteiro">
          <Container fluid id="headerCadastro">
            <Row id="cadastroInteiro">
              <Col xs={6} md={4} id="headerCadastroEsquerda">
                <Link to="/">
                  <Image
                    src={Logo}
                    width="127"
                    height="37"
                    fluid
                    id="imgLogo2"
                  />
                </Link>
              </Col>
              <Col xs={12} md={8} id="headerCadastroDireita">
                <Row id="setas">
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec" />
                    <div id="setaInversaBaixoSelec" />
                    <div id="rectangleSelec">
                      <p id="escritoSetaPlanoSelec">passo 01</p>
                      <p id="escritoSetaDescricaoSelec">Planos</p>
                    </div>
                    <div id="setaSelec" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec2" />
                    <div id="setaInversaBaixoSelec2" />
                    <div id="rectangleSelec2">
                      <p id="escritoSetaPlanoSelec">passo 02</p>
                      <p id="escritoSetaDescricaoSelec">Dados</p>
                    </div>
                    <div id="setaSelec2" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec3" />
                    <div id="setaInversaBaixoSelec3" />
                    <div id="rectangleSelec3">
                      <p id="escritoSetaPlanoSelec">passo 03</p>
                      <p id="escritoSetaDescricaoSelec">Endereço</p>
                    </div>
                    <div id="setaSelec3" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec4" />
                    <div id="setaInversaBaixoSelec4" />
                    <div id="rectangleSelec4">
                      <p id="escritoSetaPlanoSelec">passo 04</p>
                      <p
                        id="escritoSetaDescricaoSelec"
                        style={{ marginLeft: "-2px" }}
                      >
                        Questionário
                      </p>
                    </div>
                    <div id="setaSelec4" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec5" />
                    <div id="setaInversaBaixoSelec5" />
                    <div id="rectangleSelec5">
                      <p id="escritoSetaPlanoSelec">passo 05</p>
                      <p id="escritoSetaDescricaoSelec">Pagamento</p>
                    </div>
                    <div id="setaSelec5" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec6" />
                    <div id="setaInversaBaixoSelec6" />
                    <div id="rectanglePontaSelec">
                      <p id="escritoSetaObrigadoSelec">Obrigado!</p>
                    </div>
                  </Container>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>
        <Container fluid id="conteudoCadastroInteiro">
          <Container fluid id="conteudoCadastro">
            <Container className="obrigado">
              <Container className="obrigadoLivro" />
              <h1 className="obrigadoTexto">OBRIGADO!</h1>
              <p className="textoObrigado">
                Seus Livros devem chegar para você em ate 20 dias utéis.
              </p>
              <p className="textoObrigado" style={{ marginBottom: "30px" }}>
                Você receberá um e-mail com a confirmação do seu cadastro em
                breve.
              </p>
            </Container>

            <Container id="botaoCadastro">
              <ButtonGroup id="menuBotaoAssineMesesCadastro">
                <Button variant="primary" id="botaoCadastroAvancar">
                  <Link to="/">
                    <h6 className="botaoFechar">FECHAR</h6>
                  </Link>
                </Button>
              </ButtonGroup>
            </Container>
          </Container>
        </Container>
        <Footer />
      </Container>
    );
  }
}

export default Obrigado;
