import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import modalImgPessoa from "../../img/modalImgPessoa.png";
import "./index.css";
import Container from "react-bootstrap/Container";
import { Redirect } from "react-router";
import {
  idEEmailAtualLogado,
  urlPessoas,
  pegarToken,
} from "../../servicos/pessoas-service";
import swal from "sweetalert";
import Swal from "sweetalert2";
import * as modalService from "../../servicos/modal-service.js";
import ReactHtmlParser from "react-html-parser";
//<span className="circularModal"><CircularProgress /></span>

class modalCadastro extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      senha: "",
      confirmaSenha: "",
      resultado: {},
      idAtual: 0,
      redirecionar: false,
      perfilAtual: 0,
      loading: "unloading",
    };

    this.cadastrarNoBanco = this.cadastrarNoBanco.bind(this);
    this.pegarEmail = this.pegarEmail.bind(this);
    this.pegarSenha = this.pegarSenha.bind(this);
    this.confirmaSenha = this.confirmaSenha.bind(this);
    this.limparInputs = this.limparInputs.bind(this);
  }

  limparInputs() {
    this.setState({ senha: "" });
    this.setState({ confirmaSenha: "" });

    let campos = document.querySelectorAll(".modalImput");
    // console.log(campos);
    campos.forEach((e) => e.removeAttribute("disabled"));
  }

  pegarEmail(event) {
    this.setState({ email: event.target.value });
  }
  pegarSenha(event) {
    this.setState({ senha: event.target.value });
  }
  confirmaSenha(event) {
    this.setState({ confirmaSenha: event.target.value });
  }

  cadastrarNoBanco(event) {
    if (this.state.email == "") {
      swal("Insira um email valido");
    } else if (this.state.senha != this.state.confirmaSenha) {
      swal("As senhas devem ser iguais.");
    } else if (this.state.senha == "") {
      swal("Campo senha vazio.");
    } else {
      this.setState({ loading: "loading" });
      document.querySelector(".modalSubmitButton").disabled = true;
      document.querySelector(".modalImput").disabled = true;
      fetch(urlPessoas + "Cliente/CadastrarLogin", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.state.email,
          emailContato: this.state.email,
          senha: this.state.senha,
          confirmaSenha: this.state.confirmaSenha,
        }),
      })
        .then((resposta) => {
          pegarToken(resposta.headers.get("Token"));
          return resposta.json();
        })
        .then((resultado) => {
          this.setState({ loading: "unloading" });
          document.querySelector(".modalSubmitButton").disabled = false;
          idEEmailAtualLogado(resultado.id, resultado.email, resultado);

          if (resultado.mensagem) {
            if (
              resultado.mensagem ===
              "Usuário ou senha já cadastrados no sistema."
            ) {
              this.limparInputs();

              Swal.fire({
                title: "E-mail já cadastrado!",
                showConfirmButton: true,
                confirmButtonText: "Logar",
                cancelButtonText: "cancelar",
                showCancelButton: true,
              }).then((result) => {
                if (result.isConfirmed) {
                  modalService.fecharModalCadastro();
                  modalService.abrirModalLoguin();
                }
              });
            } else {
              swal(resultado.mensagem);
            }
          } else {
            this.setState({ redirecionar: true });
          }
        })
        .catch((err) => {
          this.setState({ loading: "unloading" });
          document.querySelector(".modalSubmitButton").disabled = false;
          console.log("Erro ao se conectar ao servidor: " + err);
        });
    }
    event.preventDefault();
  }

  componentDidMount() {}

  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/Cadastro" />;
    } else {
      return (
        <Container
          style={{ display: "none" }}
          id="modalCadastroInteiro"
          onClick={(e) => {
            if (
              e.target.className != "modalSubmitButton" &&
              e.target.id == "modalCadastroInteiro"
            ) {
              modalService.fecharModalCadastro();
              this.limparInputs();
            }
          }}
        >
          <div id="modal-cadastro-nucleo">
            <Image
              id="img-modal"
              src={modalImgPessoa}
              width="83"
              height="83"
              className="imgModalPessoa"
            />
            <h2>Cadastrar</h2>
            <div className={this.state.loading}>
              <CircularProgress />
            </div>
            <form onSubmit={this.cadastrarNoBanco} className="modalForm">
              <label className="modalLabel">
                <p style={{ margin: "0 0 0 5px" }}>Seu e-mail:</p>
                <input
                  className="modalImput"
                  type="text"
                  name="email"
                  onChange={this.pegarEmail}
                  value={this.state.email}
                />
              </label>
              <label className="modalLabel">
                <p style={{ margin: "0 0 0 5px" }}>Sua senha:</p>
                <input
                  className="modalImput"
                  type="password"
                  name="senha"
                  onChange={this.pegarSenha}
                  value={this.state.senha}
                />
              </label>
              <label className="modalLabel">
                <p style={{ margin: "0 0 0 5px" }}>Confirme sua senha:</p>
                <input
                  className="modalImput"
                  type="password"
                  name="senha"
                  onChange={this.confirmaSenha}
                  value={this.state.confirmaSenha}
                />
              </label>
              <input
                className="modalSubmitButton"
                style={{ marginTop: "20px" }}
                type="submit"
                value="Cadastrar"
                onClick={this.cadastrarNoBanco}
              ></input>
            </form>
          </div>
        </Container>
      );
    }
  }
}

export default modalCadastro;
