import React, { Component } from "react";
import Footer from "../../components/footer2Versao";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MenuCadastro from "./menuPlanosCadastro";
import Image from "react-bootstrap/Image";
import Logo from "../../img/logo2x.png";
import { HashLink as Link } from "react-router-hash-link";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Redirect } from "react-router";
import {
  planoEscolhidoPeriodo,
  planoEscolhidoTipo,
  entregarIdLogado,
  token,
  entregarValorEPeriodoPlanoEscolhido,
} from "../../servicos/pessoas-service";
import {
  urlAssinaturas,
  entregarAssinaturaLogada,
} from "../../servicos/assinaturas-service";
import swal from "sweetalert";

class cadastroDePlanos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecionar: false,
      redirecionarEndereco: false,
      redirecionarQuestionario: false,
      redirecionarPagamento: false,
    };

    this.cadastrarPlanos = this.cadastrarPlanos.bind(this);
    this.definirPlano = this.definirPlano.bind(this);
  }

  cadastrarPlanos(event) {
    fetch(urlAssinaturas + "NovaAssinatura", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        assinanteId: entregarIdLogado,
        planoId: this.definirPlano(planoEscolhidoPeriodo, planoEscolhidoTipo),
      }),
    })
      .then((resposta) => resposta.json())
      .then((resultado) => {
        entregarAssinaturaLogada(resultado.id);

        if (resultado.mensagem) {
          swal(resultado.mensagem);
        } else if (!resultado.pessoa.enderecos) {
          this.setState({ redirecionarEndereco: true });
        } else if (!resultado.pessoa.cpf) {
          this.setState({ redirecionar: true });
        } else if (!resultado.pessoa.questionario) {
          this.setState({ redirecionarQuestionario: true });
        } else if (!resultado.pessoa.pagamento) {
          this.setState({ redirecionarPagamento: true });
        } else {
        }
      })
      .catch((err) => {
        console.log("Erro ao se conectar ao servidor: " + err);
      });

    event.preventDefault();
  }

  definirPlano(periodo, produto) {
    const planos = {
      // periodo1Eproduto1: { codigoId: 1, valor: 79.9, periodo: 6 },
      // periodo1Eproduto2: { codigoId: 2, valor: 135.9, periodo: 1 },
      periodo1Eproduto3: { codigoId: 6, valor: 185.0, periodo: 1 },
      // periodo1Eproduto4: { codigoId: 3, valor: 199.9, periodo: 1 },
      // periodo2Eproduto1: { codigoId: 1, valor: 79.9, periodo: 6 },
      // periodo2Eproduto2: { codigoId: 5, valor: 135.9, periodo: 6 },
      // periodo2Eproduto3: { codigoId: 6, valor: 79.9, periodo: 6 },
      // periodo2Eproduto4: { codigoId: 7, valor: 175.9, periodo: 6 },
      // periodo3Eproduto1: { codigoId: 8, valor: 69.9, periodo: 12 },
      // periodo3Eproduto2: { codigoId: 9, valor: 125.9, periodo: 12 },
      periodo3Eproduto3: { codigoId: 10, valor: 160.0, periodo: 12 },
      // periodo3Eproduto4: { codigoId: 11, valor: 165.9, periodo: 12 },
    };
    entregarValorEPeriodoPlanoEscolhido(
      planos[`periodo${periodo}Eproduto${produto}`].valor,
      planos[`periodo${periodo}Eproduto${produto}`].periodo
    );

    return planos[`periodo${periodo}Eproduto${produto}`].codigoId;
  }
  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/cadastroDadosPessoais" />;
    } else if (this.state.redirecionarEndereco == true) {
      return <Redirect to="/cadastroEndereco" />;
    } else if (this.state.redirecionarQuestionario == true) {
      return <Redirect to="/Questionario" />;
    } else if (this.state.redirecionarPagamento == true) {
      return <Redirect to="/Pagamento" />;
    } else {
      return (
        <Container fluid id="inteiro">
          <Container fluid id="headerCadastro">
            <Row id="cadastroInteiro">
              <Col xs={6} md={4} id="headerCadastroEsquerda">
                <Link to="/">
                  <Image
                    src={Logo}
                    width="127"
                    height="37"
                    fluid
                    id="imgLogo2"
                  />
                </Link>
              </Col>
              <Col xs={12} md={8} id="headerCadastroDireita">
                <Row id="setas">
                  <Container id="caixaSetas">
                    <div id="setaInversaCimaSelec" />
                    <div id="setaInversaBaixoSelec" />
                    <div id="rectangleSelec">
                      <p id="escritoSetaPlanoSelec">passo 01</p>
                      <p id="escritoSetaDescricaoSelec">Planos</p>
                    </div>
                    <div id="setaSelec" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectangle">
                      <p id="escritoSetaPlano">passo 02</p>
                      <p id="escritoSetaDescricao">Dados</p>
                    </div>
                    <div id="seta" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectangle">
                      <p id="escritoSetaPlano">passo 03</p>
                      <p id="escritoSetaDescricao">Endereço</p>
                    </div>
                    <div id="seta" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectangle">
                      <p id="escritoSetaPlano">passo 04</p>
                      <p id="escritoSetaQuest">Questionário</p>
                    </div>
                    <div id="seta" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectangle">
                      <p id="escritoSetaPlano">passo 05</p>
                      <p id="escritoSetaDescricao">Pagamento</p>
                    </div>
                    <div id="seta" />
                  </Container>
                  <Container id="caixaSetas">
                    <div id="setaInversaCima" />
                    <div id="setaInversaBaixo" />
                    <div id="rectanglePonta">
                      <p id="escritoSetaObrigado">Obrigado!</p>
                    </div>
                  </Container>
                </Row>
              </Col>
            </Row>
          </Container>

          <Container fluid id="conteudoCadastroInteiro">
            <Container fluid id="conteudoCadastro">
              <Container fluid id="monteSeuPlano">
                01 - Monte seu plano como preferir!
              </Container>
              <Container fluid id="menuMonteSeuPlano">
                <MenuCadastro />
              </Container>
              <Container id="botaoCadastro">
                <ButtonGroup id="menuBotaoAssineMesesCadastro">
                  <Button variant="primary" id="botaoCadastroVoltar" disabled>
                    <h6>Voltar</h6>
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoCadastroAvancar"
                    onClick={this.cadastrarPlanos}
                  >
                    <h6 id="textoBotaoAvancar">Próximo</h6>
                  </Button>
                </ButtonGroup>
              </Container>
              <Container fluid id="jaPosuiCadastro">
                <p id="textoPossuiCadastro">
                  Já possui cadastro?{" "}
                  <Link to="/" id="linkPossuiCadastro">
                    Entrar
                  </Link>
                </p>
              </Container>
            </Container>
          </Container>
          <Footer />
        </Container>
      );
    }
  }
}

export default cadastroDePlanos;
