import React from "react";
import "./index.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { HashLink as Link } from "react-router-hash-link";
import Image from "react-bootstrap/Image";
import Cartoes from "../../img/cartoes2x.png";
import Logo from "../../img/LeituraTua_-_colorido.png";
import Midias from "../../img/midias2x.png";

function Footer() {
  return (
    <Container fluid id="rodape">
      <Container fluid id="inteiroFooter1">
        <Container fluid id="menuFooter1">
          <Row id="menuFooter1Row">
            <Col sm={6} md={4} id="colMenu1">
              <p id="paraDireita">Sobre a LeituraTua</p>
              <Link to="/QuemSomos#inteiro">Quem somos</Link>
              <Link to="/QuemSomos#conteudoParaQueServimos">
                Para que servimos
              </Link>
              <Link to="/Duvidas#inteiro">Dúvidas</Link>
            </Col>
            <Col sm={6} md={4} id="colMenu1">
              <p>
                <Link to="/">Suporte</Link>
              </p>
              {/* <p>
                <Link to="/TermosDeUso#inteiro">Termos de uso</Link>
              </p> */}
              <p>
                <a
                  href="https://www.leituratua.com.br/documentos/Pol%C3%ADtica+de+privacidade+da+empresa+LeituraTua.pdf"
                  target="_blank"
                >
                  Política de privacidade
                </a>
              </p>
              {/* <Image src={Cartoes} width="272" height="95" fluid /> */}
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid id="inteiroFooter2">
        <Container fluid id="menuFooter2">
          <Row id="menuFooter2Row">
            <Col xs={6} md={4} className="col-menu2">
              <Image src={Logo} id="img1" width="128" height="37" fluid />
            </Col>
            <Col xs={12} md={8} className="col-menu2">
              {/* <p>(44) 94444-4444</p> */}
              <p>LEITURATUA-COMERCIO DE LIVROS E CURSOS LTDA</p>
              <p>Rua Antonio de Godoy, 7241, Sala 3. </p>
              <p>Bairro: Jardim Francisco Fernandes.</p>
              <p>São José do Rio Preto/SP</p>
              <p>CEP: 15090-250</p>
              <p>cnpj: 55.100.474/0001-05</p>
              <p>ola@leituratua.com.br</p>
              {/* <Image src={Midias} id="img2" width="223" height="32" fluid /> */}
            </Col>
          </Row>
        </Container>
      </Container>
    </Container>
  );
}

export default Footer;
