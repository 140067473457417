import React, { Component } from "react";
import "./painelUsuario.css";
import Container from "react-bootstrap/Container";
import {
  entregarIdLogado,
  entregarPessoaLogada,
  urlPessoas,
  token,
} from "../../servicos/pessoas-service.js";
import { urlAssinaturas } from "../../servicos/assinaturas-service";
import { HashLink as Link } from "react-router-hash-link";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import ReactIf from "../../rcIf";
import Paginacao from "../../components/paginacao/paginacao";
import {
  paginacaoIndiceInicial,
  paginacaoIndiceFinal,
} from "../../servicos/paginacao-service";

class HistoricoCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoaAtual: entregarPessoaLogada,
      idAtual: entregarIdLogado,
      indiceInicio: 1,
      indiceFim: 1,
      livrosEnviados: [],
      livrosEnvviadosInvertido: [],
    };

    this.obterHistoricoAssinatura();

    this.obterHistoricoAssinatura = this.obterHistoricoAssinatura.bind(this);
    // this.mockLivros = this.mockLivros.bind(this);
  }

  obterHistoricoAssinatura() {
    let livro = "/static/media/";

    fetch(urlAssinaturas + "ObterResumoAssinatura", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        assinanteId: entregarIdLogado,
        selecionarResumo: 2,
      }),
    })
      .then((resposta) => resposta.json())
      .then((resultadoJson) => {
        for (let i = 0; i < resultadoJson.length; i++) {
          this.setState({
            livrosEnviados: [
              ...this.state.livrosEnviados,
              {
                livroTitulo: resultadoJson[i].ultimoLivroRecomendadoTitulo,
                LivroAutor: resultadoJson[i].ultimoLivroRecomendadoAutor,
                livroImagem: resultadoJson[i].ultimoLivroRecomendadoCapa,
                carta:
                  "Nossa aqui deve ter um link para o pdf de uma carta. e agora X_X.",
                dataEntrega: resultadoJson[i].dataEntrega,
                mesReferencia: resultadoJson[i].referenciaEntrega,
                pedidoReferencia: resultadoJson[i].numeroPedido,
              },
            ],
          });
        }
      })
      .catch((err) => {
        console.log("Erro ao se logar: " + err);
      });
  }

  receberInicio = (valorRecebido) => {
    this.setState({ indiceInicio: valorRecebido });
    return valorRecebido;
  };

  receberFim = (valorRecebido) => {
    this.setState({ indiceFim: valorRecebido });
    return valorRecebido;
  };

  // componentDidMount(){
  //   this.obterHistoricoAssinatura();
  // }

  render() {
    return (
      <Container fluid className="inteiroMainCliente">
        <Container fluid className="mainCliente">
          <Container fluid className="tituloResumoCliente">
            <h2>Olá, {this.state.pessoaAtual.nome}!</h2>
            <p>Assinatura : #00{this.state.idAtual}</p>
          </Container>

          <Container>
            {this.state.livrosEnviados.map((element, index) => (
              <ReactIf
                rcIf={
                  index >= this.state.indiceInicio - 1 &&
                  index <= this.state.indiceFim - 1
                }
              >
                <Container key={index} fluid className="ultimoLivroCliente">
                  <div id="pedido-referencia">
                    <p>Numero do Pedido: {element.pedidoReferencia}</p>
                  </div>
                  <ReactIf rcIf={index === 0}>
                    <p>O último livro recomendado para você foi:</p>
                  </ReactIf>
                  <ReactIf rcIf={index != 0}>
                    <p>Referencia: {element.mesReferencia}</p>
                  </ReactIf>
                  <Container className="containerLivroAtual">
                    <Container fluid className="livroAtual">
                      <h2>{element.livroTitulo}</h2>
                      <h3>{element.LivroAutor}</h3>
                      <Link to="#">
                        <h5>
                          Leia aqui seu texto biblioterapêutico deste livro
                        </h5>
                      </Link>
                    </Container>
                    <Container className="imagemLivro">
                      <Image src={element.livroImagem} className="livro" />
                    </Container>
                  </Container>
                  <p>Data de Entrega: {element.dataEntrega}</p>
                </Container>
              </ReactIf>
            ))}
          </Container>

          <Container id="paginacao-historico">
            <Paginacao
              quantidadeElementos={this.state.livrosEnviados.length}
              setIndiceInicio={this.receberInicio}
              setIndiceFim={this.receberFim}
            />
          </Container>
        </Container>
      </Container>
    );
  }
}

export default HistoricoCliente;
