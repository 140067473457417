import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import modalImgPessoa from "../../img/modalImgPessoa.png";
import ModalCadastro from "./modalCadastro";
import "./index.css";
import texto1 from "../../img/img1tx2x.png";
import texto2 from "../../img/img2tx2x.png";
import liv1txt1 from "../../img/img1lv1tx2x.png";
import liv2txt2 from "../../img/img2liv2tx2x.png";
import { planoEscolhido } from "../../servicos/pessoas-service.js";
import * as modalService from "../../servicos/modal-service.js";

function ConteudoMenu({ botaoPeriodo, botaoLivro }) {
  const marginValor = {
    marginLeft: "50px",
  };
  const marginSaibaMais = {
    marginBottom: "-45px",
  };
  if (botaoPeriodo == 1 && botaoLivro == 1) {
    planoEscolhido(1, 1);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1 style={marginValor}>R$79,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3>
              Você recebe por mês, durante seis meses, personalizado e
              individual com indicação de 1 livro para ajudar a entender melhor
              suas angústias, seus problemas e celebrar suas alegrias e suas
              conquistas.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMais">
              saiba mais
            </Link>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 1) {
    planoEscolhido(2, 1);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1 style={marginValor}>R$79,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3>
              Você recebe por mês, durante seis meses, personalizado e
              individual com indicação de 1 livro para ajudar a entender melhor
              suas angústias, seus problemas e celebrar suas alegrias e suas
              conquistas.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMais">
              saiba mais
            </Link>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 1) {
    planoEscolhido(3, 1);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1 style={marginValor}>R$69,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3>
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 2) {
    planoEscolhido(1, 2);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1>R$159,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3>
              Você receberá em casa pontualmente um único kit de experiência
              biblioterapêutica, que inclui: o livro físico e a carta de análise
              personalizada e curadoria literária. O kit da LeituraTua é inédito
              e pensado para você para ajudar no enfrentamento de angústias e
              melhorar sua saúde mental com o poder da literatura.
            </h3>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 2) {
    planoEscolhido(2, 2);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1>R$135,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3>
              Você recebe por mês, durante seis meses, 2 textos personalizados e
              individuais com indicações de 2 livros para ajudar a entender
              melhor suas angústias, seus problemas e celebrar suas alegrias e
              suas conquistas.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMais">
              saiba mais
            </Link>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 2) {
    planoEscolhido(3, 2);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1>R$125,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3>
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 3) {
    planoEscolhido(1, 3);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <div>
              <h1 style={marginValor}>R$185,00</h1>
              <p id="frete"> + frete</p>
            </div>
            <h3 id="contedoDescricaoTexto">
              Você receberá em casa pontualmente um único kit de experiência
              biblioterapêutica, que inclui: o livro físico e a carta de análise
              personalizada e curadoria literária. O kit da LeituraTua é inédito
              e pensado para você para ajudar no enfrentamento de angústias e
              melhorar sua saúde mental com o poder da literatura.
            </h3>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 3) {
    planoEscolhido(2, 3);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <div>
              <h1 style={marginValor}>R$185,00</h1>

              <p id="frete"> + frete</p>
              <p id="mensais">mensais</p>
            </div>
            <h3 id="contedoDescricaoTexto">
              Você recebe por mês, durante seis meses, 1 livro para ajudar a
              entender melhor suas angústias, seus problemas e celebrar suas
              alegrias e suas conquistas.
            </h3>
            <p id="mais3">+</p>
            <h3 id="contedoDescricaoTexto2">
              explicativo personalizado e individual para seu caso.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMais">
              saiba mais
            </Link>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 3) {
    planoEscolhido(3, 3);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <div>
              <h1 style={marginValor}>R$160,00</h1>

              <p id="frete"> + frete</p>
              <p id="mensais">mensais</p>
            </div>
            <h3 id="contedoDescricaoTexto">
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 4) {
    planoEscolhido(1, 4);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <div>
              <h1>R$185,00</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </div>
            <h3 id="contedoDescricaoTexto">
              Você receberá em casa pontualmente um único kit de experiência
              biblioterapêutica, que inclui: o livro físico e a carta de análise
              personalizada e curadoria literária. O kit da LeituraTua é inédito
              e pensado para você para ajudar no enfrentamento de angústias e
              melhorar sua saúde mental com o poder da literatura.
            </h3>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 4) {
    planoEscolhido(2, 4);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1>R$175,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3 id="contedoDescricaoTexto">
              Você recebe por mês, durante seis meses, 2 livros para ajudar a
              entender melhor suas angústias, seus problemas e celebrar suas
              alegrias e suas conquistas.
            </h3>
            <p id="mais3">+</p>
            <h3 id="contedoDescricaoTexto2" style={marginSaibaMais}>
              2 textos explicativos personalizados e individuais para seu caso.
            </h3>
            <Link to="/DetalhesPlanos#semestral" id="linkSaibaMais">
              saiba mais
            </Link>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 4) {
    planoEscolhido(3, 4);
    return (
      <Row id="planosConteudo">
        <Col xs={12} md={6} id="conteudo1">
          <Container id="conteudoAssine">
            <Image
              src={texto1}
              id="img2"
              width="136"
              height="159"
              id="imgPlanos"
            />
          </Container>
        </Col>
        <Col xs={12} md={6} id="conteudo2>">
          <Container id="conteudoDescricao">
            <Container>
              <h1>R$165,90</h1>
              <p id="mais">+</p>
              <p id="frete">frete</p>
              <p id="mensais">mensais</p>
            </Container>
            <h3 id="contedoDescricaoTexto">
              Você receberá em casa um novo kit de experiência biblioterapêutica
              a cada mês, durante um ano. Os livros serão inéditos a cada mês,
              sempre pensados por meio do que analisamos em seu
              questionário.Dessa forma, ao longo de um ano, poderemos, juntos,
              tirar importantes lições dos grandes escritores para melhorar sua
              saúde mental.
            </h3>
          </Container>
        </Col>
        <Col xs={12} md={6} id="menuBotaoAssine">
          <Button
            variant="primary"
            id="botaoImgPlanos"
            onClick={(e) => modalService.abrirModalCadastro(e)}
          >
            <p id="botaoImgPlanosP">Assine</p>
          </Button>
        </Col>
      </Row>
    );
  }
}

export default ConteudoMenu;
