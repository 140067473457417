import React, { Component } from "react";
import "./painelCliente.css";
import Container from "react-bootstrap/Container";
import {
  entregarIdLogado,
  entregarPessoaLogada,
  urlPessoas,
  token,
} from "../../servicos/pessoas-service.js";
import swal from "sweetalert";
import { Redirect } from "react-router";

class DadosPessoaisCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecionar: false,
      pessoaAtual: entregarPessoaLogada,
      idAtual: entregarIdLogado,
      nome: "",
      cpf: "",
      telefone: "",
      email: "",
      senha: "",
      nascimento: "",

      cep: "",
      estado: "",
      cidade: "",
      rua: "",
      numero: "",
      bairro: "",
      complemento: "",

      cepEntrega: "",
      estadoEntrega: "",
      cidadeEntrega: "",
      ruaEntrega: "",
      numeroEntrega: "",
      bairroEntrega: "",
      complementoEntrega: "",

      idEndereco: 0,
      idEnderecoEntrega: 0,
      igualado: 0,
    };

    this.igualarEndereco = this.igualarEndereco.bind(this);
    this.pegarDados = this.pegarDados.bind(this);
    this.pegarBairro = this.pegarBairro.bind(this);
    this.pegarBairroEntrega = this.pegarBairroEntrega.bind(this);
    this.pegarCep = this.pegarCep.bind(this);
    this.pegarCepEntrega = this.pegarCepEntrega.bind(this);
    this.pegarCidade = this.pegarCidade.bind(this);
    this.pegarCidadeEntrega = this.pegarCidadeEntrega.bind(this);
    this.pegarComplementoEntrega = this.pegarComplementoEntrega.bind(this);
    this.pegarEmail = this.pegarEmail.bind(this);
    this.pegarEstado = this.pegarEstado.bind(this);
    this.pegarEstadoEntrega = this.pegarEstadoEntrega.bind(this);
    this.pegarNumero = this.pegarNumero.bind(this);
    this.pegarNumeroEntrega = this.pegarNumeroEntrega.bind(this);
    this.pegarRua = this.pegarRua.bind(this);
    this.pegarRuaEntrega = this.pegarRuaEntrega.bind(this);
    this.pegarTelefone = this.pegarTelefone.bind(this);
    this.pegarComplemento = this.pegarComplemento.bind(this);
    this.alterarDados = this.alterarDados.bind(this);
    this.alterarSenha = this.alterarSenha.bind(this);
  }

  alterarSenha() {
    const message = `<h1>Olá ${this.state.nome}</h1>
                       <h3>Você está recebendo este email pois foi solicitado a sua alteração em nosso site.
                            clique <a href="#">aqui</a> para altera-lo.</h3>
                       <h4>Muito obrigado.</h4>`;

    swal({ title: message, icon: "info" });
  }

  igualarEndereco() {
    if (this.state.igualado == 0) {
      this.setState({ igualado: 1 });
      this.setState({ cepEntrega: this.state.cep });
      this.setState({ estadoEntrega: this.state.estado });
      this.setState({ cidadeEntrega: this.state.cidade });
      this.setState({ ruaEntrega: this.state.rua });
      this.setState({ numeroEntrega: this.state.numero });
      this.setState({ bairroEntrega: this.state.bairro });
      this.setState({ complementoEntrega: this.state.complemento });
    } else {
      this.setState({ igualado: 0 });
      this.setState({ cepEntrega: " " });
      this.setState({ estadoEntrega: " " });
      this.setState({ cidadeEntrega: " " });
      this.setState({ ruaEntrega: " " });
      this.setState({ numeroEntrega: " " });
      this.setState({ bairroEntrega: " " });
      this.setState({ complementoEntrega: " " });
    }
  }

  alterarDados() {
    fetch(urlPessoas + "Cliente/AlterarDadosPessoais", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(this.pegarDados()),
    })
      .then((resposta) => resposta.json())
      .then((resultadoJson) => {
        swal({
          title: "Dados alterados com sucesso.",
          icon: "info",
        });
      })
      .catch((err) => {
        console.log("Erro ao se logar: " + err);
      });
  }

  pegarTelefone(event) {
    this.setState({ telefone: event.target.value });
  }
  pegarEmail(event) {
    this.setState({ email: event.target.value });
  }
  pegarCep(event) {
    this.setState({ cep: event.target.value });
  }
  pegarEstado(event) {
    this.setState({ estado: event.target.value });
  }
  pegarCidade(event) {
    this.setState({ cidade: event.target.value });
  }
  pegarRua(event) {
    this.setState({ rua: event.target.value });
  }
  pegarNumero(event) {
    this.setState({ numero: event.target.value });
  }
  pegarBairro(event) {
    this.setState({ bairro: event.target.value });
  }
  pegarComplemento(event) {
    this.setState({ complemento: event.target.value });
  }
  pegarCepEntrega(event) {
    this.setState({ cepEntrega: event.target.value });
  }
  pegarEstadoEntrega(event) {
    this.setState({ estadoEntrega: event.target.value });
  }
  pegarCidadeEntrega(event) {
    this.setState({ cidadeEntrega: event.target.value });
  }
  pegarRuaEntrega(event) {
    this.setState({ ruaEntrega: event.target.value });
  }
  pegarNumeroEntrega(event) {
    this.setState({ numeroEntrega: event.target.value });
  }
  pegarBairroEntrega(event) {
    this.setState({ bairroEntrega: event.target.value });
  }
  pegarComplementoEntrega(event) {
    this.setState({ complementoEntrega: event.target.value });
  }

  pegarDados() {
    let enderecos = [
      {
        id: this.state.idEndereco,
        tipoEndereco: 1,
        cep: this.state.cep,
        rua: this.state.rua,
        numero: this.state.numero,
        complemento: this.state.complemento,
        bairro: this.state.bairro,
        cidade: this.state.cidade,
        uf: this.state.estado,
      },
      {
        id: this.state.idEnderecoEntrega,
        tipoEndereco: 2,
        cep: this.state.cepEntrega,
        rua: this.state.ruaEntrega,
        numero: this.state.numeroEntrega,
        complemento: this.state.complementoEntrega,
        bairro: this.state.bairroEntrega,
        cidade: this.state.cidadeEntrega,
        uf: this.state.estadoEntrega,
      },
    ];

    let pessoaAlterar = {
      id: this.state.idAtual,
      nome: this.state.nome,
      dataNasimento: this.state.nascimento,
      email: this.state.email,
      telefone: this.state.telefone,
      enderecoViewModel: enderecos,
    };

    return pessoaAlterar;
  }

  componentDidMount() {
    fetch(urlPessoas + "Cliente/Procurar", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id: this.state.idAtual,
        nome: "string",
        dataNasimento: "string",
        telefone: "string",
        emailContato: this.state.emailAtual,
      }),
    })
      .then((resposta) => resposta.json())
      .then((resultadoJson) => {
        this.setState({ resultado: resultadoJson });
        this.setState({ nome: resultadoJson.nome });
        this.setState({ cpf: resultadoJson.cpf });
        this.setState({
          telefone: resultadoJson.telefone ? resultadoJson.telefone : "",
        });
        this.setState({ email: resultadoJson.email });
        this.setState({ senha: resultadoJson.senha });
        this.setState({ nascimento: resultadoJson.dataNasimento });

        Array.from(resultadoJson.enderecos).forEach((enderecosPessoa) => {
          if (enderecosPessoa.tipoEndereco === 1) {
            this.setState({ idEndereco: enderecosPessoa.id });
            this.setState({ cep: enderecosPessoa.cep });
            this.setState({ estado: enderecosPessoa.uf });
            this.setState({ cidade: enderecosPessoa.cidade });
            this.setState({ rua: enderecosPessoa.rua });
            this.setState({ numero: enderecosPessoa.numero });
            this.setState({ bairro: enderecosPessoa.bairro });
            this.setState({ complemento: enderecosPessoa.complemento });
          } else if (enderecosPessoa.tipoEndereco === 2) {
            this.setState({ idEnderecoEntrega: enderecosPessoa.id });
            this.setState({ cepEntrega: enderecosPessoa.cep });
            this.setState({ estadoEntrega: enderecosPessoa.uf });
            this.setState({ cidadeEntrega: enderecosPessoa.cidade });
            this.setState({ ruaEntrega: enderecosPessoa.rua });
            this.setState({ numeroEntrega: enderecosPessoa.numero });
            this.setState({ bairroEntrega: enderecosPessoa.bairro });
            this.setState({ complementoEntrega: enderecosPessoa.complemento });
          } else {
            swal("Endereço invalido");
          }
        });
      })

      .catch((err) => {
        console.log("Erro ao se logar: " + err);
      });
  }

  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/Home" />;
    } else {
      return (
        <Container fluid className="inteiroMainCliente">
          <Container fluid className="mainCliente">
            <Container className="h1Container">
              <h1>Dados Pessoais</h1>
            </Container>
            <Container className="containerInputs">
              <Container className="imputContainer2">
                <label className="mainClienteLabel">
                  <p>Nome Completo:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="nome"
                    style={{ color: "black" }}
                    value={this.state.nome}
                    readOnly
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>CPF:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="cpf"
                    style={{ color: "black" }}
                    value={this.state.cpf}
                    readOnly
                  />
                </label>
              </Container>
              <Container className="imputContainer2">
                <label className="mainClienteLabel">
                  <p>Telefone:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="nome"
                    value={this.state.telefone}
                    onChange={this.pegarTelefone}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>E-mail:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="cpf"
                    value={this.state.email}
                    onChange={this.pegarEmail}
                  />
                </label>
              </Container>
              <Container className="imputContainer1">
                <label className="mainClienteLabel">
                  <p>Sua Senha:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="nome"
                    value={this.state.senha}
                    readOnly
                  />
                </label>

                <input
                  className="mainClienteImputBotao"
                  type="submit"
                  name="alterarSenha"
                  onClick={this.alterarSenha}
                  value="Alterar Senha"
                />
              </Container>
            </Container>

            <Container className="h1Container">
              <h1>Endereço:</h1>
            </Container>
            <Container className="containerInputs">
              <Container className="imputContainer3">
                <label className="mainClienteLabel">
                  <p>CEP:</p>
                  <input
                    className="mainClienteImputCep"
                    type="text"
                    name="cep"
                    value={this.state.cep}
                    onChange={this.pegarCep}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Estado:</p>
                  <input
                    className="mainClienteImputEstado"
                    type="text"
                    name="estado"
                    value={this.state.estado}
                    onChange={this.pegarEstado}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Cidade:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="cidade"
                    value={this.state.cidade}
                    onChange={this.pegarCidade}
                  />
                </label>
              </Container>
              <Container className="imputContainer2">
                <label className="mainClienteLabel">
                  <p>Rua:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="rua"
                    value={this.state.rua}
                    onChange={this.pegarRua}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Numero:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="numero"
                    value={this.state.numero}
                    onChange={this.pegarNumero}
                  />
                </label>
              </Container>
              <Container className="imputContainer2">
                <label className="mainClienteLabel">
                  <p>Bairro:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="bairro"
                    value={this.state.bairro}
                    onChange={this.pegarBairro}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Complemento:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="complemento"
                    value={this.state.complemento}
                    onChange={this.pegarComplemento}
                  />
                </label>
              </Container>
              <Container className="imputCheckbox">
                <input
                  className="mainClienteImputCheckbox"
                  onChange={this.igualarEndereco}
                  type="checkbox"
                  name="mesmoEndereco"
                />
                <label className="mainClienteLabel">
                  <p>Mesmo endereço de entrega</p>
                </label>
              </Container>
            </Container>

            <Container className="h1Container">
              <h1>Endereço de Entrega:</h1>
            </Container>
            <Container className="containerInputs">
              <Container className="imputContainer3">
                <label className="mainClienteLabel">
                  <p>CEP:</p>
                  <input
                    className="mainClienteImputCep"
                    type="text"
                    name="cep"
                    value={this.state.cepEntrega}
                    onChange={this.pegarCepEntrega}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Estado:</p>
                  <input
                    className="mainClienteImputEstado"
                    type="text"
                    name="estado"
                    value={this.state.estadoEntrega}
                    onChange={this.pegarEstadoEntrega}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Cidade:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="cidade"
                    value={this.state.cidadeEntrega}
                    onChange={this.pegarCidadeEntrega}
                  />
                </label>
              </Container>
              <Container className="imputContainer2">
                <label className="mainClienteLabel">
                  <p>Rua:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="rua"
                    value={this.state.ruaEntrega}
                    onChange={this.pegarRuaEntrega}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Numero:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="numero"
                    value={this.state.numeroEntrega}
                    onChange={this.pegarNumeroEntrega}
                  />
                </label>
              </Container>
              <Container className="imputContainer2">
                <label className="mainClienteLabel">
                  <p>Bairro:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="bairro"
                    value={this.state.bairroEntrega}
                    onChange={this.pegarBairroEntrega}
                  />
                </label>
                <label className="mainClienteLabel">
                  <p>Complemento:</p>
                  <input
                    className="mainClienteImput"
                    type="text"
                    name="complemento"
                    value={this.state.complementoEntrega}
                    onChange={this.pegarComplementoEntrega}
                  />
                </label>
              </Container>
            </Container>
            <Container className="botaoConfirmarAlteracoes">
              <input
                className="mainClienteImputBotaoConfirmarAlteracoes"
                onClick={this.alterarDados}
                type="submit"
                name="confirmar"
                value="Confirmar alterações"
              />
            </Container>
          </Container>
        </Container>
      );
    }
  }
}

export default DadosPessoaisCliente;
