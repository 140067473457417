import React, { Component } from "react";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Footer from "../../components/footer2Versao";
import "./index.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Logo from "../../img/logo2x.png";
import { HashLink as Link } from "react-router-hash-link";
import { token } from "../../servicos/pessoas-service";
import {
  urlQuestionario,
  respostaGuardadaOutros,
  guardarValorOutros,
} from "../../servicos/questionario-service";
import { entregarIdLogado } from "../../servicos/pessoas-service";
import { assinaturaLogada } from "../../servicos/assinaturas-service";
import ReactIf from "../../rcIf";
import { Redirect } from "react-router";
import swal from "sweetalert";
import InputGroup from "react-bootstrap/InputGroup";

class Questionario extends Component {
  timeOut;
  timeOutPeso;
  timeOutResp;
  timeOutUltResp;
  constructor(props) {
    super(props);
    this.state = {
      questionarioRecebido: [],
      perguntasRespondidas: [],
      perguntasRespondidasPeso: [],
      idPessoa: entregarIdLogado,
      redirecionar: false,
      assinaturaId: assinaturaLogada,
      valorInputOutros: "",
      controleAbrirOutros: [{ existe: false, aberto: false }],
      arrayDeValoresIguais: [],
      loading: "unloading",
    };
    this.pegarQuestionario = this.pegarQuestionario.bind(this);
    this.valoresCheckbox = this.valoresCheckbox.bind(this);
    this.cadastrarQuestionario = this.cadastrarQuestionario.bind(this);
    this.redirecionarTela = this.redirecionarTela.bind(this);
    this.validarQuestionario = this.validarQuestionario.bind(this);
    this.find_obj = this.find_obj.bind(this);
    this.find_nome = this.find_nome.bind(this);
    this.every_nodeList = this.every_nodeList.bind(this);
    this.pegarValorInputOutros = this.pegarValorInputOutros.bind(this);
    this.abrirOutros = this.abrirOutros.bind(this);
    this.buscarInputNumberDuplicado =
      this.buscarInputNumberDuplicado.bind(this);
  }

  validarQuestionario(event) {
    if (this.state.arrayDeValoresIguais.length > 0) {
      swal("Algumas perguntas tem respostas iguais");
    } else {
      fetch(urlQuestionario + "ValidarPerguntasRespondidas", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-expose-headers": "*",
          Authorization: `Bearer ${
            token ? token : sessionStorage.getItem("token")
          }`,
        },
        body: JSON.stringify({
          assinaturaId: this.state.assinaturaId,
          questionarioId: 1,
          assinanteId: this.state.idPessoa,
        }),
      })
        .then((resposta) => resposta.json())
        .then((resultadoJson) => {
          if (
            resultadoJson.mensagem ==
            "Faltam algumas perguntas a serem respondidas."
          ) {
            if (!event) {
            } else {
              swal(resultadoJson.mensagem);
            }
          } else {
            if (!event) {
              let proximo = document.getElementById("botaoCadastroAvancar");
              proximo.removeAttribute("disabled");
              this.setState({ loading: "unloading" });
            } else {
              this.setState({ redirecionar: true });
            }
          }
        })
        .catch((err) => {
          console.log("Erro ao enviar questionario: " + err);
        });
    }
  }

  find_obj(nome) {
    return this.state.perguntasRespondidasPeso
      .map((e) => {
        return e.nome;
      })
      .indexOf(nome);
  }

  find_nome(nomeRecebido) {
    return this.state.perguntasRespondidasPeso.map((e) => {
      return e.nome.includes(nomeRecebido);
    });
  }

  every_nodeList(node) {
    let arrayResultado = [];
    for (let i = 0; i < node.length; i++) {
      if (!isNaN(node[i].valueAsNumber) && node[i].valueAsNumber > 0) {
        arrayResultado.push(true);
      } else if (isNaN(node[i].valueAsNumber) || node[i].valueAsNumber <= 0) {
        arrayResultado.push(false);
      }
    }
    return arrayResultado;
  }

  buscarInputNumberDuplicado(node) {
    let valores = [];
    this.state.arrayDeValoresIguais = [];
    for (let i = 0; i < node.length; i++) {
      if (!isNaN(node[i].valueAsNumber)) {
        valores.push(node[i].valueAsNumber);
      }
    }
    let retorno = valores.filter((este, i) => {
      return valores.indexOf(este) !== i;
    });
    if (retorno.length === 0) {
      return false;
    } else {
      const irState = retorno.filter((este, i) => {
        return retorno.indexOf(este) === i;
      });
      this.state.arrayDeValoresIguais = irState;
      return true;
    }
  }

  pegarValorInputOutros(
    event,
    numeroRespostas,
    numeroDaPergunta,
    tipoPergunta
  ) {
    this.setState({ valorInputOutros: event.target.value });
    guardarValorOutros(event.target.value);
    clearTimeout(this.timeOut);

    this.timeOut = setTimeout(() => {
      if (tipoPergunta == "Peso") {
        this.cadastrarQuestionario(
          numeroDaPergunta,
          numeroRespostas,
          this.state.perguntasRespondidasPeso,
          "Peso"
        );
      } else if (tipoPergunta === "Múltipla Escolha") {
        this.cadastrarQuestionario(
          numeroDaPergunta,
          numeroRespostas,
          this.state.perguntasRespondidas,
          "multiplas"
        );
      }
    }, 2000);
  }

  valoresCheckbox(
    event,
    numeroRespostas,
    numeroDaPergunta,
    tipoPergunta,
    textoPergunta,
    indexOutros
  ) {
    // prepara o caminho para começar a fazer as validações.
    clearTimeout(this.timeOutResp);

    const pergunta = document.getElementsByName(event.target.name);
    const perguntaPeso = document.querySelectorAll(`#${event.target.id}`);

    const schema = tipoPergunta === "Múltipla Escolha" ? 1 : 2;
    this.state.perguntasRespondidas = [];
    this.state.perguntasRespondidasPeso = [];
    pergunta.forEach((elemento) => elemento.removeAttribute("disabled"));
    perguntaPeso.forEach((elemento) => elemento.removeAttribute("disabled"));
    const classe = "textoRespostaDuplicada";

    this.timeOutResp = setTimeout(() => {
      for (let i = 0; i < perguntaPeso.length; i++) {
        perguntaPeso[i].classList.remove(classe);
        perguntaPeso[i].nextElementSibling.classList.remove(classe);
      }
      //verifica valores nos inputs do html e se tiver inclui na state de array, apenas para perguntas com peso
      if (tipoPergunta === "Peso") {
        if (this.every_nodeList(perguntaPeso).some((el) => el === true)) {
          if (this.buscarInputNumberDuplicado(perguntaPeso)) {
            for (let i = 0; i < perguntaPeso.length; i++) {
              this.state.arrayDeValoresIguais.forEach((el) => {
                if (el === perguntaPeso[i].valueAsNumber) {
                  perguntaPeso[i].classList.add(classe);
                  perguntaPeso[i].nextElementSibling.classList.add(classe);
                }
              });
            }
          }
          if (numeroRespostas === 0) {
            for (let i = 0; i < perguntaPeso.length; i++) {
              if (
                parseInt(perguntaPeso[i].valueAsNumber) !== 0 &&
                !isNaN(parseInt(perguntaPeso[i].valueAsNumber))
              ) {
                this.state.perguntasRespondidasPeso.push({
                  nome: perguntaPeso[i].name,
                  peso: parseInt(perguntaPeso[i].valueAsNumber),
                });
                if (
                  textoPergunta == "Outros" ||
                  textoPergunta == "outros" ||
                  textoPergunta == "outro" ||
                  textoPergunta == "Outro"
                ) {
                  this.state.controleAbrirOutros[indexOutros].aberto = true;
                }
              }
            }

            this.cadastrarQuestionario(
              numeroDaPergunta,
              numeroRespostas,
              this.state.perguntasRespondidasPeso,
              "pesos"
            );
          } else {
            for (let i = 0; i < perguntaPeso.length; i++) {
              if (
                parseInt(perguntaPeso[i].valueAsNumber) !== 0 &&
                !isNaN(parseInt(perguntaPeso[i].valueAsNumber))
              ) {
                if (
                  this.state.perguntasRespondidasPeso.length < numeroRespostas
                ) {
                  this.state.perguntasRespondidasPeso.push({
                    nome: perguntaPeso[i].name,
                    peso: parseInt(perguntaPeso[i].valueAsNumber),
                  });
                  if (
                    textoPergunta == "Outros" ||
                    textoPergunta == "outros" ||
                    textoPergunta == "outro" ||
                    textoPergunta == "Outro"
                  ) {
                    this.state.controleAbrirOutros[indexOutros].aberto = true;
                  } else if (
                    perguntaPeso[i].valueAsNumber == 0 &&
                    (textoPergunta == "Outros" ||
                      textoPergunta == "outros" ||
                      textoPergunta == "outro" ||
                      textoPergunta == "Outro")
                  ) {
                    this.state.controleAbrirOutros[indexOutros].aberto = false;
                    this.setState({ valorInputOutros: "" });
                  }
                  if (
                    this.state.perguntasRespondidasPeso.length ===
                    numeroRespostas
                  ) {
                    this.cadastrarQuestionario(
                      numeroDaPergunta,
                      numeroRespostas,
                      this.state.perguntasRespondidasPeso,
                      "pesos"
                    );
                    for (let j = 0; j < perguntaPeso.length; j++) {
                      if (
                        parseInt(perguntaPeso[j].valueAsNumber) === 0 ||
                        isNaN(perguntaPeso[j].valueAsNumber)
                      ) {
                        perguntaPeso[j].setAttribute("disabled", "disabled");
                      }
                    }
                  }
                }
              }
            }
          }
        } else if (
          this.every_nodeList(perguntaPeso).some((el) => el === false) &&
          (textoPergunta == "Outros" ||
            textoPergunta == "outros" ||
            textoPergunta == "outro" ||
            textoPergunta == "Outro")
        ) {
          this.state.controleAbrirOutros[indexOutros].aberto = false;
          this.setState({ valorInputOutros: "" });
        }
      }
    }, 500);

    // Trata perguntas de multipla escolha
    if (tipoPergunta === "Múltipla Escolha") {
      if (numeroRespostas === 0) {
        for (let i = 0; i < pergunta.length; i++) {
          if (
            pergunta[i].checked &&
            !this.state.perguntasRespondidas.includes(pergunta[i].value)
          ) {
            this.state.perguntasRespondidas.push(pergunta[i].value);
            if (
              textoPergunta == "Outros" ||
              textoPergunta == "outros" ||
              textoPergunta == "outro" ||
              textoPergunta == "Outro"
            ) {
              this.state.controleAbrirOutros[indexOutros].aberto = true;
            }
          } else if (
            !pergunta[i].checked &&
            (textoPergunta == "Outros" ||
              textoPergunta == "outros" ||
              textoPergunta == "outro" ||
              textoPergunta == "Outro")
          ) {
            this.state.controleAbrirOutros[indexOutros].aberto = false;
            this.setState({ valorInputOutros: "" });
          }
        }
        this.cadastrarQuestionario(
          numeroDaPergunta,
          numeroRespostas,
          this.state.perguntasRespondidas,
          "multiplas"
        );
      } else {
        for (let i = 0; i < pergunta.length; i++) {
          if (
            pergunta[i].checked &&
            !this.state.perguntasRespondidas.includes(pergunta[i].value) &&
            this.state.perguntasRespondidas.length < numeroRespostas
          ) {
            this.state.perguntasRespondidas.push(pergunta[i].value);
            pergunta[i].checked = true;

            if (
              textoPergunta == "Outros" ||
              textoPergunta == "outros" ||
              textoPergunta == "outro" ||
              textoPergunta == "Outro"
            ) {
              this.state.controleAbrirOutros[indexOutros].aberto = true;
            }
          } else if (
            !pergunta[i].checked &&
            (textoPergunta == "Outros" ||
              textoPergunta == "outros" ||
              textoPergunta == "outro" ||
              textoPergunta == "Outro")
          ) {
            this.state.controleAbrirOutros[indexOutros].aberto = false;
            this.setState({ valorInputOutros: "" });
          } else if (
            pergunta[i].checked &&
            !this.state.perguntasRespondidas.includes(pergunta[i].value)
          ) {
            pergunta[i].checked = false;
            pergunta[i].setAttribute("disabled", "disabled");
          }
        }

        if (this.state.perguntasRespondidas.length === numeroRespostas) {
          this.cadastrarQuestionario(
            numeroDaPergunta,
            numeroRespostas,
            this.state.perguntasRespondidas,
            "multiplas"
          );
          pergunta.forEach((elemento) => {
            if (!elemento.checked) {
              elemento.setAttribute("disabled", "disabled");
            }
          });
        }
      }
    }

    // console.log(indexOutros)
    // console.log(this.state.controleAbrirOutros[indexOutros])
  }

  cadastrarQuestionario(
    numeroPerguntaRespondida,
    quantidadeDeRespostas,
    respostas,
    schema
  ) {
    clearTimeout(this.timeOutPeso);
    let nomes = [];
    let tipoPergunta = schema === "multiplas" ? 1 : 2;

    respostas.forEach((elemento) => {
      let nome;
      if (!elemento.nome) {
        nome = elemento;
      } else if (elemento.nome) {
        if (elemento.nome.length === 20) {
          nome = elemento.nome.slice(11);
        } else if (elemento.nome.length === 19) {
          nome = elemento.nome.slice(10);
        }
      }
      nomes.push(nome);
    });

    let resposta1 = nomes.includes("resposta3") ? true : false;
    let resposta2 = nomes.includes("resposta4") ? true : false;
    let resposta3 = nomes.includes("resposta5") ? true : false;
    let resposta4 = nomes.includes("resposta6") ? true : false;
    let resposta5 = nomes.includes("resposta7") ? true : false;
    let resposta6 = nomes.includes("resposta8") ? true : false;
    let resposta7 = nomes.includes("resposta9") ? true : false;
    let resposta8 = nomes.includes("resposta10") ? true : false;
    let resposta9 = nomes.includes("resposta11") ? true : false;
    let resposta10 = nomes.includes("resposta12") ? true : false;
    let resposta11 = nomes.includes("resposta13") ? true : false;
    let resposta12 = nomes.includes("resposta14") ? true : false;
    let resposta13 = nomes.includes("resposta15") ? true : false;
    let resposta14 = nomes.includes("resposta16") ? true : false;
    let resposta15 = nomes.includes("resposta17") ? true : false;
    let resposta16 = nomes.includes("resposta18") ? true : false;
    let resposta17 = nomes.includes("resposta19") ? true : false;
    let resposta18 = nomes.includes("resposta20") ? true : false;
    let resposta19 = nomes.includes("resposta21") ? true : false;
    let resposta20 = nomes.includes("resposta22") ? true : false;
    let resposta21 = nomes.includes("resposta23") ? true : false;
    let resposta22 = nomes.includes("resposta24") ? true : false;
    let resposta23 = nomes.includes("resposta25") ? true : false;
    let resposta24 = nomes.includes("resposta26") ? true : false;
    let resposta25 = nomes.includes("resposta27") ? true : false;
    let resposta26 = nomes.includes("resposta28") ? true : false;
    let resposta27 = nomes.includes("resposta29") ? true : false;
    let resposta28 = nomes.includes("resposta30") ? true : false;
    let resposta29 = nomes.includes("resposta31") ? true : false;
    let resposta30 = nomes.includes("resposta32") ? true : false;
    let resposta31 = nomes.includes("resposta33") ? true : false;
    let resposta32 = nomes.includes("resposta34") ? true : false;
    let resposta33 = nomes.includes("resposta35") ? true : false;
    let resposta34 = nomes.includes("resposta36") ? true : false;
    let resposta35 = nomes.includes("resposta37") ? true : false;
    let resposta36 = nomes.includes("resposta38") ? true : false;
    let resposta37 = nomes.includes("resposta39") ? true : false;
    let resposta38 = nomes.includes("resposta40") ? true : false;
    let resposta39 = nomes.includes("resposta41") ? true : false;
    let resposta40 = nomes.includes("resposta42") ? true : false;
    let resposta41 = nomes.includes("resposta43") ? true : false;
    let resposta42 = nomes.includes("resposta44") ? true : false;
    let resposta43 = nomes.includes("resposta45") ? true : false;
    let resposta44 = nomes.includes("resposta46") ? true : false;
    let resposta45 = nomes.includes("resposta47") ? true : false;
    let resposta46 = nomes.includes("resposta48") ? true : false;
    let resposta47 = nomes.includes("resposta49") ? true : false;
    let resposta48 = nomes.includes("resposta50") ? true : false;
    let resposta49 = nomes.includes("resposta51") ? true : false;
    let resposta50 = nomes.includes("resposta52") ? true : false;

    this.timeOutPeso = setTimeout(() => {
      fetch(urlQuestionario + "Perguntas/Responder", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "access-control-expose-headers": "*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          pessoaId: this.state.idPessoa,
          numeroPergunta: numeroPerguntaRespondida,
          quantasRespostasAceitas: quantidadeDeRespostas,
          perguntaSchema: parseInt(tipoPergunta),
          resposta1: resposta1,
          resposta2: resposta2,
          resposta3: resposta3,
          resposta4: resposta4,
          resposta5: resposta5,
          resposta6: resposta6,
          resposta7: resposta7,
          resposta8: resposta8,
          resposta9: resposta9,
          resposta10: resposta10,
          resposta11: resposta11,
          resposta12: resposta12,
          resposta13: resposta13,
          resposta14: resposta14,
          resposta15: resposta15,
          resposta16: resposta16,
          resposta17: resposta17,
          resposta18: resposta18,
          resposta19: resposta19,
          resposta20: resposta20,
          resposta20: resposta20,
          resposta21: resposta21,
          resposta22: resposta22,
          resposta23: resposta23,
          resposta24: resposta24,
          resposta25: resposta25,
          resposta26: resposta26,
          resposta27: resposta27,
          resposta28: resposta28,
          resposta29: resposta29,
          resposta30: resposta30,
          resposta30: resposta30,
          resposta31: resposta31,
          resposta32: resposta32,
          resposta33: resposta33,
          resposta34: resposta34,
          resposta35: resposta35,
          resposta36: resposta36,
          resposta37: resposta37,
          resposta38: resposta38,
          resposta39: resposta39,
          resposta40: resposta40,
          resposta40: resposta40,
          resposta41: resposta41,
          resposta42: resposta42,
          resposta43: resposta43,
          resposta44: resposta44,
          resposta45: resposta45,
          resposta46: resposta46,
          resposta47: resposta47,
          resposta48: resposta48,
          resposta49: resposta49,
          resposta50: resposta50,
          respostaPeso1: resposta1
            ? respostas[nomes.indexOf("resposta3")].peso
            : 0,
          respostaPeso2: resposta2
            ? respostas[nomes.indexOf("resposta4")].peso
            : 0,
          respostaPeso3: resposta3
            ? respostas[nomes.indexOf("resposta5")].peso
            : 0,
          respostaPeso4: resposta4
            ? respostas[nomes.indexOf("resposta6")].peso
            : 0,
          respostaPeso5: resposta5
            ? respostas[nomes.indexOf("resposta7")].peso
            : 0,
          respostaPeso6: resposta6
            ? respostas[nomes.indexOf("resposta8")].peso
            : 0,
          respostaPeso7: resposta7
            ? respostas[nomes.indexOf("resposta9")].peso
            : 0,
          respostaPeso8: resposta8
            ? respostas[nomes.indexOf("resposta10")].peso
            : 0,
          respostaPeso9: resposta9
            ? respostas[nomes.indexOf("resposta11")].peso
            : 0,
          respostaPeso10: resposta10
            ? respostas[nomes.indexOf("resposta12")].peso
            : 0,
          respostaPeso11: resposta11
            ? respostas[nomes.indexOf("resposta13")].peso
            : 0,
          respostaPeso12: resposta12
            ? respostas[nomes.indexOf("resposta14")].peso
            : 0,
          respostaPeso13: resposta13
            ? respostas[nomes.indexOf("resposta15")].peso
            : 0,
          respostaPeso14: resposta14
            ? respostas[nomes.indexOf("resposta16")].peso
            : 0,
          respostaPeso15: resposta15
            ? respostas[nomes.indexOf("resposta17")].peso
            : 0,
          respostaPeso16: resposta16
            ? respostas[nomes.indexOf("resposta18")].peso
            : 0,
          respostaPeso17: resposta17
            ? respostas[nomes.indexOf("resposta19")].peso
            : 0,
          respostaPeso18: resposta18
            ? respostas[nomes.indexOf("resposta20")].peso
            : 0,
          respostaPeso19: resposta19
            ? respostas[nomes.indexOf("resposta21")].peso
            : 0,
          respostaPeso20: resposta20
            ? respostas[nomes.indexOf("resposta22")].peso
            : 0,
          respostaPeso21: resposta21
            ? respostas[nomes.indexOf("resposta23")].peso
            : 0,
          respostaPeso22: resposta22
            ? respostas[nomes.indexOf("resposta24")].peso
            : 0,
          respostaPeso23: resposta23
            ? respostas[nomes.indexOf("resposta25")].peso
            : 0,
          respostaPeso24: resposta24
            ? respostas[nomes.indexOf("resposta26")].peso
            : 0,
          respostaPeso25: resposta25
            ? respostas[nomes.indexOf("resposta27")].peso
            : 0,
          respostaPeso26: resposta26
            ? respostas[nomes.indexOf("resposta28")].peso
            : 0,
          respostaPeso27: resposta27
            ? respostas[nomes.indexOf("resposta29")].peso
            : 0,
          respostaPeso28: resposta28
            ? respostas[nomes.indexOf("resposta30")].peso
            : 0,
          respostaPeso29: resposta29
            ? respostas[nomes.indexOf("resposta31")].peso
            : 0,
          respostaPeso30: resposta30
            ? respostas[nomes.indexOf("resposta32")].peso
            : 0,
          respostaPeso31: resposta31
            ? respostas[nomes.indexOf("resposta33")].peso
            : 0,
          respostaPeso32: resposta32
            ? respostas[nomes.indexOf("resposta34")].peso
            : 0,
          respostaPeso33: resposta33
            ? respostas[nomes.indexOf("resposta35")].peso
            : 0,
          respostaPeso34: resposta34
            ? respostas[nomes.indexOf("resposta36")].peso
            : 0,
          respostaPeso35: resposta35
            ? respostas[nomes.indexOf("resposta37")].peso
            : 0,
          respostaPeso36: resposta36
            ? respostas[nomes.indexOf("resposta38")].peso
            : 0,
          respostaPeso37: resposta37
            ? respostas[nomes.indexOf("resposta39")].peso
            : 0,
          respostaPeso38: resposta38
            ? respostas[nomes.indexOf("resposta40")].peso
            : 0,
          respostaPeso39: resposta39
            ? respostas[nomes.indexOf("resposta41")].peso
            : 0,
          respostaPeso40: resposta40
            ? respostas[nomes.indexOf("resposta42")].peso
            : 0,
          respostaPeso41: resposta41
            ? respostas[nomes.indexOf("resposta43")].peso
            : 0,
          respostaPeso42: resposta42
            ? respostas[nomes.indexOf("resposta44")].peso
            : 0,
          respostaPeso43: resposta43
            ? respostas[nomes.indexOf("resposta45")].peso
            : 0,
          respostaPeso44: resposta44
            ? respostas[nomes.indexOf("resposta46")].peso
            : 0,
          respostaPeso45: resposta45
            ? respostas[nomes.indexOf("resposta47")].peso
            : 0,
          respostaPeso46: resposta46
            ? respostas[nomes.indexOf("resposta48")].peso
            : 0,
          respostaPeso47: resposta47
            ? respostas[nomes.indexOf("resposta49")].peso
            : 0,
          respostaPeso48: resposta48
            ? respostas[nomes.indexOf("resposta50")].peso
            : 0,
          respostaPeso49: resposta49
            ? respostas[nomes.indexOf("resposta51")].peso
            : 0,
          respostaPeso50: resposta50
            ? respostas[nomes.indexOf("resposta52")].peso
            : 0,
          textoOutros: this.state.valorInputOutros,
        }),
      })
        .then((resposta) => resposta.json())
        .then((resultadoJson) => {
          this.validarQuestionario();
        })
        .catch((err) => {
          console.log("Erro ao enviar questionario: " + err);
        });
    }, 200);
  }

  redirecionarTela() {
    this.setState({ redirecionar: true });
  }

  pegarQuestionario() {
    let proximo = document.getElementById("botaoCadastroAvancar");
    proximo.setAttribute("disabled", "true");
    this.setState({ loading: "loading-questionario" });
    fetch(urlQuestionario + "Perguntas/Listar", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "access-control-expose-headers": "*",
        Authorization: `Bearer ${
          token ? token : sessionStorage.getItem("token")
        }`,
      },
    })
      .then((resposta) => resposta.json())
      .then((resultadoJson) => {
        this.setState({ questionarioRecebido: resultadoJson });

        this.state.questionarioRecebido.forEach((elemento) => {
          if (
            Object.values(elemento).includes("Outros") ||
            Object.values(elemento).includes("Outro") ||
            Object.values(elemento).includes("outros") ||
            Object.values(elemento).includes("outro")
          ) {
            this.state.controleAbrirOutros.push({
              existe: true,
              aberto: false,
            });
          } else {
            this.state.controleAbrirOutros.push({
              existe: false,
              aberto: false,
            });
          }
        });

        // console.log(this.state.controleAbrirOutros);
      })
      .catch((err) => {
        console.log("Erro ao se logar: " + err);
      });
  }

  abrirOutros() {}

  componentDidMount() {
    this.pegarQuestionario();
  }

  render() {
    if (this.state.redirecionar == true) {
      return <Redirect to="/pagamento" />;
    } else {
      return (
        <Container fluid id="inteiro">
          <Container fluid id="cadastroInteiro">
            <Container fluid id="headerCadastro">
              <Row id="cadastroInteiro">
                <Col xs={6} md={4} id="headerCadastroEsquerda">
                  <Link to="/">
                    <Image
                      src={Logo}
                      width="127"
                      height="37"
                      fluid
                      id="imgLogo2"
                    />
                  </Link>
                </Col>
                <Col xs={12} md={8} id="headerCadastroDireita">
                  <Row id="setas">
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec" />
                      <div id="setaInversaBaixoSelec" />
                      <div id="rectangleSelec">
                        <p id="escritoSetaPlanoSelec">passo 01</p>
                        <p id="escritoSetaDescricaoSelec">Planos</p>
                      </div>
                      <div id="setaSelec" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec2" />
                      <div id="setaInversaBaixoSelec2" />
                      <div id="rectangleSelec2">
                        <p id="escritoSetaPlanoSelec">passo 02</p>
                        <p id="escritoSetaDescricaoSelec">Dados</p>
                      </div>
                      <div id="setaSelec2" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec3" />
                      <div id="setaInversaBaixoSelec3" />
                      <div id="rectangleSelec3">
                        <p id="escritoSetaPlanoSelec">passo 03</p>
                        <p id="escritoSetaDescricaoSelec">Endereço</p>
                      </div>
                      <div id="setaSelec3" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCimaSelec4" />
                      <div id="setaInversaBaixoSelec4" />
                      <div id="rectangleSelec4">
                        <p id="escritoSetaPlanoSelec">passo 04</p>
                        <p
                          id="escritoSetaDescricaoSelec"
                          style={{ marginLeft: "-2px" }}
                        >
                          Questionário
                        </p>
                      </div>
                      <div id="setaSelec4" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCima" />
                      <div id="setaInversaBaixo" />
                      <div id="rectangle">
                        <p id="escritoSetaPlano">passo 05</p>
                        <p id="escritoSetaDescricao">Pagamento</p>
                      </div>
                      <div id="seta" />
                    </Container>
                    <Container id="caixaSetas">
                      <div id="setaInversaCima" />
                      <div id="setaInversaBaixo" />
                      <div id="rectanglePonta">
                        <p id="escritoSetaObrigado">Obrigado!</p>
                      </div>
                    </Container>
                  </Row>
                </Col>
              </Row>
            </Container>
          </Container>
          <Container fluid id="conteudoCadastroInteiro">
            <Container fluid id="conteudoCadastro">
              <Container fluid id="monteSeuPlano">
                04 - Nos fale sobre você.
              </Container>
              <Container fluid id="monteSeuPlano" style={{ fontSize: "18px" }}>
                <p>
                  Responda o questionário para que possamos conhecer você e lhe
                  proporcionar melhores experiências pela leitura!
                </p>
                .
              </Container>

              <Container className="questionario">
                <Container
                  fluid
                  id="monteSeuPlano"
                  className="perguntasQuestionario"
                >
                  {this.state.questionarioRecebido.map((element, indexP) => (
                    <Container key={indexP} fluid className="pergunta">
                      <p id="textoPergunta">
                        Pergunta {indexP + 1} : {element.pergunta}
                      </p>
                      <ReactIf
                        rcIf={
                          element.quantasRespostasValidas > 1 &&
                          element.tipoPergunta === "Múltipla Escolha"
                        }
                      >
                        <i style={{ fontSize: "12px" }}>
                          você deve escolher {element.quantasRespostasValidas}{" "}
                          respostas na sua ordem de importância
                        </i>
                      </ReactIf>
                      <ReactIf
                        rcIf={
                          element.quantasRespostasValidas > 1 &&
                          element.tipoPergunta === "Peso"
                        }
                      >
                        <i style={{ fontSize: "12px" }}>
                          você deve escolher {element.quantasRespostasValidas}{" "}
                          respostas na sua ordem de importância
                        </i>
                      </ReactIf>
                      <ReactIf rcIf={element.quantasRespostasValidas === 1}>
                        <i style={{ fontSize: "12px" }}>
                          você deve escolher {element.quantasRespostasValidas}{" "}
                          respostas na sua ordem de importância
                        </i>
                      </ReactIf>
                      <ReactIf
                        rcIf={
                          element.quantasRespostasValidas === 0 &&
                          element.tipoPergunta === "Múltipla Escolha"
                        }
                      >
                        <i style={{ fontSize: "12px" }}>
                          escolha quantas respostas achar necessárias
                        </i>
                      </ReactIf>
                      <ReactIf
                        rcIf={
                          element.quantasRespostasValidas === 0 &&
                          element.tipoPergunta === "Peso"
                        }
                      >
                        <i style={{ fontSize: "12px" }}>
                          escolha quantas respostas achar necessárias
                        </i>
                      </ReactIf>
                      {Object.keys(element).map((key, indexR) => (
                        <Container key={key}>
                          <ReactIf
                            rcIf={
                              key.includes("textoResposta") &&
                              element[key] &&
                              indexR >= 1 &&
                              element.tipoPergunta === "Múltipla Escolha"
                            }
                          >
                            <Container id="containerResposta">
                              <input
                                type="checkbox"
                                id="checkboxResposta"
                                name={`pergunta${indexP}`}
                                value={`resposta${indexR - 2}`}
                                onClick={(e) =>
                                  this.valoresCheckbox(
                                    e,
                                    element.quantasRespostasValidas,
                                    element.numeroPergunta,
                                    element.tipoPergunta,
                                    element[key],
                                    indexP + 1
                                  )
                                }
                              />
                              <label id="textoResposta">{element[key]}</label>
                            </Container>
                            <ReactIf
                              rcIf={
                                element[key] == "Outros" ||
                                element[key] == "outros" ||
                                element[key] == "outro" ||
                                element[key] == "Outro"
                              }
                            >
                              <Container id="containerOutros">
                                <input
                                  className="inputOutros"
                                  id="formInput"
                                  type="text"
                                  size="20"
                                  name={`respostaOutros${indexR - 2}`}
                                  onKeyUp={(e) =>
                                    this.pegarValorInputOutros(
                                      e,
                                      element.quantasRespostasValidas,
                                      element.numeroPergunta,
                                      element.tipoPergunta
                                    )
                                  }
                                />
                                <i
                                  style={{
                                    fontSize: "12px",
                                    margin: "0 0 0 10px",
                                  }}
                                >
                                  descreva
                                </i>
                              </Container>
                            </ReactIf>
                          </ReactIf>
                          <ReactIf
                            rcIf={
                              key.includes("textoResposta") &&
                              element[key] &&
                              indexR >= 1 &&
                              element.tipoPergunta === "Peso"
                            }
                          >
                            <Container id="containerResposta">
                              <input
                                className="inputNumber"
                                type="number"
                                min="0"
                                max="99"
                                id={`pergunta${indexP}`}
                                name={`pergunta${indexP}eresposta${indexR - 2}`}
                                onClick={(e) =>
                                  this.valoresCheckbox(
                                    e,
                                    element.quantasRespostasValidas,
                                    element.numeroPergunta,
                                    element.tipoPergunta,
                                    element[key],
                                    indexP + 1
                                  )
                                }
                              />
                              <label id="textoResposta">{element[key]}</label>
                            </Container>
                            <ReactIf
                              rcIf={
                                element[key] == "Outros" ||
                                element[key] == "outros" ||
                                element[key] == "outro" ||
                                element[key] == "Outro"
                              }
                            >
                              <Container id="containerOutros">
                                <input
                                  className="inputOutros"
                                  id="formInput"
                                  type="text"
                                  size="20"
                                  name={`respostaOutros${indexR - 2}`}
                                  onKeyUp={(e) => {
                                    clearTimeout(this.timeOutUltResp);
                                    this.timeOut = setTimeout(() => {
                                      this.setState({
                                        loading: "loading-questionario",
                                      });
                                    }, 1000);
                                    this.pegarValorInputOutros(
                                      e,
                                      element.quantasRespostasValidas,
                                      element.numeroPergunta,
                                      element.tipoPergunta
                                    );
                                  }}
                                />
                                <i
                                  style={{
                                    fontSize: "12px",
                                    margin: "0 0 0 10px",
                                  }}
                                >
                                  descreva
                                </i>
                              </Container>
                            </ReactIf>
                          </ReactIf>
                        </Container>
                      ))}
                    </Container>
                  ))}
                </Container>
              </Container>

              <Container id="botaoCadastro">
                <ButtonGroup id="menuBotaoAssineMesesCadastro">
                  <Button variant="primary" id="botaoCadastroVoltar">
                    <Link to="/cadastroEndereco">
                      <h6>Voltar</h6>
                    </Link>
                  </Button>
                  <Button
                    variant="primary"
                    id="botaoCadastroAvancar"
                    onClick={this.validarQuestionario}
                  >
                    <div className={this.state.loading}>
                      <CircularProgress size="20px" id="circulo" />
                    </div>
                    <h6 id="textoBotaoAvancar">Próximo</h6>
                  </Button>
                </ButtonGroup>
              </Container>
              <Container></Container>
            </Container>
          </Container>
          <Footer />
        </Container>
      );
    }
  }
}

export default Questionario;
