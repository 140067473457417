import React from "react";
import "./indexHeaderPainelDeUsuario.css";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Logo from "../../img/logo LT - branco.png";
import {
  idAtualLogado,
  entregarIdLogado,
} from "../../servicos/pessoas-service";
import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";

function headerPainelDeUsuario() {
  return (
    <Container fluid className="menuPainelUsuario">
      <Container className="menuContainerPainelUsuario">
        <Image className="imagemLogoHeader" src={Logo} fluid />
        <Container fluid className="menuHeaderPainelUsuario">
          <ul className="menuHeaderPainelUsuario">
            <li className="menuLink">
              <Link className="menuLink" to="#comoFuncionaInteiro">
                Perfil
              </Link>
            </li>
            <li className="menuLink">
              <Link className="menuLink" to="/">
                Duvidas
              </Link>
            </li>
          </ul>
        </Container>
        <Container fluid className="menuSair">
          <button
            className="sair"
            onClick={() => {
              window.location = "/";
              idAtualLogado("", "");
              document.querySelector(".sair").disabled = true;
            }}
          >
            SAIR
          </button>
        </Container>
      </Container>
    </Container>
  );
}

export default headerPainelDeUsuario;
