import React, { useState } from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ConteudoMenu from "./conteudoMenuCadastro.js";
import "./index.css";
import {
  planoEscolhidoPeriodo,
  planoEscolhidoTipo,
} from "../../servicos/pessoas-service.js";
import {
  pegarPlanoEscolhidoPeriodo,
  pegarPlanoEscolhidoTipo,
} from "../../servicos/pessoas-service.js";

function MenuPlanoCadastro() {
  const [botaoPeriodo, botaoClicadoPeriodo] = useState(planoEscolhidoPeriodo);
  const [botaoLivro, botaoClicadoLivro] = useState(planoEscolhidoTipo);

  var periodo = 1;
  var livros = 3;

  if (botaoPeriodo == 1) {
    periodo = 1;
    pegarPlanoEscolhidoPeriodo(1);
  } else if (botaoPeriodo == 2) {
    periodo = 2;
    pegarPlanoEscolhidoPeriodo(2);
  } else if (botaoPeriodo == 3) {
    periodo = 3;
    pegarPlanoEscolhidoPeriodo(3);
  }
  if (botaoLivro == 1) {
    livros = 1;
    pegarPlanoEscolhidoTipo(1);
  } else if (botaoLivro == 2) {
    livros = 2;
    pegarPlanoEscolhidoTipo(2);
  } else if (botaoLivro == 3) {
    livros = 3;
    pegarPlanoEscolhidoTipo(3);
  } else if (botaoLivro == 4) {
    livros = 4;
    pegarPlanoEscolhidoTipo(4);
  }

  const butonStyle = {
    background: "#0098DA",
  };

  if (botaoPeriodo == 1 && botaoLivro == 1) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro">
                <Col id="menuDireitaPeriodoTexto">
                  <h1>1 Texto</h1>
                </Col>
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Semestral</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                  disabled
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>

        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 1) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro">
                <Col id="menuDireitaPeriodoTexto">
                  <h1>1 Texto</h1>
                </Col>
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Semestral</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                  disabled
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>

        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 1) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro">
                <Col id="menuDireitaPeriodoTexto">
                  <h1>1 Texto</h1>
                </Col>
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Anual</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                  disabled
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>

        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 2) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro">
                <Col id="menuDireitaPeriodoTexto">
                  <h1>2 Textos</h1>
                </Col>
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Mensal</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>

        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 2) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro">
                <Col id="menuDireitaPeriodoTexto">
                  <h1>2 Textos</h1>
                </Col>
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Semestral</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 2) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro">
                <Col id="menuDireitaPeriodoTexto">
                  <h1>2 Textos</h1>
                </Col>
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Anual</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 3) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro2">
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Mensal</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                  style={butonStyle}
                >
                  <h6>Mensal</h6>
                </Button>
                {/* <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button> */}
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                >
                  
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 3) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro2">
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Semestral</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                  disabled
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 3) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro2">
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Anual</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                >
                  <h6>Mensal</h6>
                </Button>
                {/* <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button> */}
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                >
                  
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 1 && botaoLivro == 4) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro2">
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Mensal</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 2 && botaoLivro == 4) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro2">
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Semestral</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  } else if (botaoPeriodo == 3 && botaoLivro == 4) {
    return (
      <Container fluid id="planosInteiroCadastro">
        <Container fluid id="planosMenuCastro">
          <Col xs={12} md={6} id="menuAssineMesesCadastro">
            <Row>
              <Col md={4} id="menuDireitaPlanosCadastro">
                <h1>Planos</h1>
              </Col>
              <Col md={8} id="menuDireitaPeriodoCadastro2">
                <Col id="menuDireitaPeriodoPeriodoCadastro">
                  <h1>Anual</h1>
                </Col>
              </Col>
            </Row>
          </Col>

          <Col xs={12} md={6} id="menuAssineLivrosCadastro">
            <Container id="navAssineCadastro">
              <ButtonGroup id="menuBotaoAssineLivrosCadastro1">
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(1)}
                >
                  <h6>Mensal</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(2)}
                >
                  <h6>Semestral</h6>
                </Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoAssineCadastro"
                  onClick={() => botaoClicadoPeriodo(3)}
                >
                  <h6>Anual</h6>
                </Button>
              </ButtonGroup>
              {/* <ButtonGroup id="menuBotaoAssineLivrosCadastro">
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(1)}
                ></Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(2)}
                >
                  <h6>2 textos</h6>
                </Button>
                <Button
                  variant="primary"
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(3)}
                ></Button>
                <Button
                  variant="primary"
                  style={butonStyle}
                  id="botaoLivrosCadastro"
                  onClick={() => botaoClicadoLivro(4)}
                >
                  <h6>2 textos </h6>
                  <p>+ 2 livros</p>
                </Button>
              </ButtonGroup> */}
            </Container>
          </Col>
        </Container>
        <Container fluid id="planosConteudoCadastro">
          <ConteudoMenu botaoPeriodo={botaoPeriodo} botaoLivro={botaoLivro} />
        </Container>
      </Container>
    );
  }
}

export default MenuPlanoCadastro;
