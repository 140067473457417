import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import "./paginacao.css";
import {
  paginacaoPegarIndiceInicial,
  paginacaoPegarIndiceFinal,
} from "../../servicos/paginacao-service";

class Paginacao extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paginaAtual: 1,
      totalDePaginas: 1,
      inciceInicial: 0,
      indiceFinal: 0,
    };
    this.atualizarLimitesDaPagina = this.atualizarLimitesDaPagina.bind(this);
    this.proximaPagina = this.proximaPagina.bind(this);
    this.paginaAnterior = this.paginaAnterior.bind(this);
    this.calcularTotalDePaginas = this.calcularTotalDePaginas.bind(this);
  }

  componentDidMount() {
    this.atualizarLimitesDaPagina();
    this.calcularTotalDePaginas();
  }

  componentWillReceiveProps() {
    this.calcularTotalDePaginas();
  }

  atualizarLimitesDaPagina() {
    let final = this.state.paginaAtual * 3;
    const inicial = final - 2;

    this.setState({ indiceInicial: inicial });
    this.setState({ indiceFinal: final });
    this.props.setIndiceInicio(inicial);
    this.props.setIndiceFim(final);
  }

  proximaPagina() {
    if (this.state.totalDePaginas == 0) {
    } else if (this.state.paginaAtual !== this.state.totalDePaginas) {
      this.state.paginaAtual++;
    }
    this.atualizarLimitesDaPagina();
  }

  paginaAnterior() {
    if (this.state.paginaAtual >= 2) {
      this.state.paginaAtual--;
    }
    this.atualizarLimitesDaPagina();
  }

  calcularTotalDePaginas() {
    let totalDePaginasAtual = Math.ceil(this.props.quantidadeElementos / 3);
    this.setState({ totalDePaginas: totalDePaginasAtual });
  }

  render() {
    return (
      <Container className="paginacao-inteiro">
        <Container className="paginacao-nucleo">
          <p onClick={this.paginaAnterior}>Anterior</p>
          <Container className="valores-paginas">
            {[...Array(this.state.totalDePaginas)].map((elemento, index) => (
              <div
                key={index}
                className={
                  this.state.paginaAtual - 1 === index ? "paginaAtual" : ""
                }
              >
                {index + 1}
              </div>
            ))}
          </Container>
          <p onClick={this.proximaPagina}>Próximo</p>
        </Container>
      </Container>
    );
  }
}

export default Paginacao;
