import React, { Component } from "react";

class ReactIf extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (this.props.rcIf) {
      return <div>{this.props.children}</div>;
    } else {
      return null;
    }
  }
}

export default ReactIf;
