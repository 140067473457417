import React, { Component } from "react";
import "./painelCliente.css";
import Container from "react-bootstrap/Container";
import {
  idAtualLogado,
  entregarIdLogado,
  entregarPessoaLogada,
  urlPessoas,
  token,
  emailLogado,
} from "../../servicos/pessoas-service.js";
import HeaderPainelDeUsuario from "../../components/headerPainelDeUsuario";
import DadosPessoaisCliente from "./dadosPessoaisCliente.js";
import ResumoCliente from "./resumoCliente.js";
import HistoricoCliente from "./historicoCliente.js";
import CartoesCliente from "./cartoesCliente.js";
import { Redirect } from "react-router";
import Swal from "sweetalert2";

class painelCLiente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pessoaAtual: entregarPessoaLogada,
      idAtual: entregarIdLogado,
      redirecionar: false,
      redirecionarEndereco: false,
      redirecionarDadosPessoais: false,

      pagina: <ResumoCliente className="dadosCliente" />,

      botaoAtivoResumo: "botaoMainCliente botaoAtivo",
      h1AtivoResumo: "h1Botao h1BotaoAtivo",

      botaoAtivoDadosPessoais: "botaoMainCliente",
      h1AtivoDadosPessoais: "h1Botao",

      botaoAtivoHistorico: "botaoMainCliente",
      h1AtivoHistorico: "h1Botao",

      botaoAtivoCartoes: "botaoMainCliente",
      h1AtivoCartoes: "h1Botao",
    };

    this.botaoClicadoResumo = this.botaoClicadoResumo.bind(this);
    this.botaoClicadoDadosPessoais = this.botaoClicadoDadosPessoais.bind(this);
    this.botaoClicadoHistorico = this.botaoClicadoHistorico.bind(this);
    this.botaoClicadoCartoes = this.botaoClicadoCartoes.bind(this);
  }

  botaoClicadoResumo() {
    this.setState({ botaoAtivoResumo: "botaoMainCliente botaoAtivo" });
    this.setState({ h1AtivoResumo: "h1Botao h1BotaoAtivo" });

    this.setState({ botaoAtivoDadosPessoais: "botaoMainCliente" });
    this.setState({ h1AtivoDadosPessoais: "h1Botao" });

    this.setState({ botaoAtivoHistorico: "botaoMainCliente" });
    this.setState({ h1AtivoHistorico: "h1Botao" });

    this.setState({ botaoAtivoCartoes: "botaoMainCliente" });
    this.setState({ h1AtivoCartoes: "h1Botao" });

    this.setState({ pagina: <ResumoCliente className="dadosCliente" /> });
  }

  botaoClicadoDadosPessoais() {
    this.setState({ botaoAtivoResumo: "botaoMainCliente" });
    this.setState({ h1AtivoResumo: "h1Botao" });

    this.setState({ botaoAtivoDadosPessoais: "botaoMainCliente botaoAtivo" });
    this.setState({ h1AtivoDadosPessoais: "h1Botao h1BotaoAtivo" });

    this.setState({ botaoAtivoHistorico: "botaoMainCliente" });
    this.setState({ h1AtivoHistorico: "h1Botao" });

    this.setState({ botaoAtivoCartoes: "botaoMainCliente" });
    this.setState({ h1AtivoCartoes: "h1Botao" });

    this.setState({
      pagina: <DadosPessoaisCliente className="dadosCliente" />,
    });
  }

  botaoClicadoHistorico() {
    this.setState({ botaoAtivoResumo: "botaoMainCliente" });
    this.setState({ h1AtivoResumo: "h1Botao" });

    this.setState({ botaoAtivoDadosPessoais: "botaoMainCliente " });
    this.setState({ h1AtivoDadosPessoais: "h1Botao " });

    this.setState({ botaoAtivoHistorico: "botaoMainCliente botaoAtivo" });
    this.setState({ h1AtivoHistorico: "h1Botao h1BotaoAtivo" });

    this.setState({ botaoAtivoCartoes: "botaoMainCliente" });
    this.setState({ h1AtivoCartoes: "h1Botao" });

    this.setState({ pagina: <HistoricoCliente className="dadosCliente" /> });
  }

  botaoClicadoCartoes() {
    this.setState({ botaoAtivoResumo: "botaoMainCliente" });
    this.setState({ h1AtivoResumo: "h1Botao" });

    this.setState({ botaoAtivoDadosPessoais: "botaoMainCliente " });
    this.setState({ h1AtivoDadosPessoais: "h1Botao " });

    this.setState({ botaoAtivoHistorico: "botaoMainCliente" });
    this.setState({ h1AtivoHistorico: "h1Botao" });

    this.setState({ botaoAtivoCartoes: "botaoMainCliente botaoAtivo" });
    this.setState({ h1AtivoCartoes: "h1Botao h1BotaoAtivo" });

    this.setState({ pagina: <CartoesCliente className="dadosCliente" /> });
  }

  componentDidMount() {
    // fetch(urlPessoas+"Cliente/Procurar",{
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'Authorization':`Bearer ${token}`
    //             },
    //             body: JSON.stringify({
    //                 "id": this.state.idAtual
    //             }),
    //         })
    //         .then((resposta) => resposta.json())
    //         .then((resultadoJson) => {
    //             this.setState({resultado: resultadoJson});
    //           if(resultadoJson.mensagem === "Falta terminar de cadastrar os dados pessoais."){
    //             Swal.fire({
    //               icon: 'question',
    //               title:'Seu cadastro ainda não foi concluido.',
    //               html: 'Gostaria de continuar o seu cadastro?',
    //               showCancelButton: true,
    //               showConfirmButton: true,
    //               cancelButtonColor: '#B29DCA',
    //             }).then((result)=>{
    //               if(result.isConfirmed){
    //                 this.setState({redirecionarDadosPessoais: true});
    //               }else if(result.dismiss === Swal.DismissReason.cancel){
    //                 this.setState({redirecionar: true});
    //               }
    //             })
    //           }
    //           else if(resultadoJson.mensagem === "Endereço não cadastrado."){
    //             Swal.fire({
    //               icon: 'question',
    //               title:'Seu cadastro ainda não foi concluido.',
    //               html: 'Gostaria de continuar o seu cadastro?',
    //               showCancelButton: true,
    //               showConfirmButton: true,
    //               cancelButtonColor: '#B29DCA',
    //             }).then((result)=>{
    //               if(result.isConfirmed){
    //                 this.setState({redirecionarEndereco: true});
    //               }else if(result.dismiss === Swal.DismissReason.cancel){
    //                 this.setState({redirecionar: true});
    //               }
    //             })
    //           }
    //           else  if(!resultadoJson.hasOwnProperty('id')){
    //             this.setState({redirecionar: true});
    //           }
    //         })
    //         .catch(err => {
    //             console.log("Erro ao se logar: " + err);
    //         });
  }

  render() {
    const botaoAtivo = {
      backgroundColor: "#F6EFF6",
    };

    if (this.state.redirecionar == true) {
      return <Redirect to="/Home" />;
    } else if (this.state.redirecionarDadosPessoais == true) {
      return <Redirect to="/cadastroDadosPessoais" />;
    } else if (this.state.redirecionarEndereco == true) {
      return <Redirect to="/cadastroEndereco" />;
    } else {
      return (
        <Container fluid className="inteiroPaineCliente">
          <HeaderPainelDeUsuario className="cabecalhoPainelUsuario" />
          <Container fluid className="mainPainelCliente">
            <Container className="inteiroMainClienteMenu">
              <Container fluid className="menuCliente">
                <button
                  className={this.state.botaoAtivoResumo}
                  onClick={this.botaoClicadoResumo}
                >
                  <h1 className={this.state.h1AtivoResumo}>Resumo</h1>
                </button>
                <button
                  className={this.state.botaoAtivoDadosPessoais}
                  onClick={this.botaoClicadoDadosPessoais}
                >
                  <h1 className={this.state.h1AtivoDadosPessoais}>
                    Dados Pessoais
                  </h1>
                </button>
                {/* <button
                  className={this.state.botaoAtivoHistorico}
                  onClick={this.botaoClicadoHistorico}
                >
                  <h1 className={this.state.h1AtivoHistorico}>Histórico</h1>
                </button> */}
                {/* <button className={this.state.botaoAtivoCartoes}  onClick={this.botaoClicadoCartoes}><h1 className={this.state.h1AtivoCartoes} >Pagamentos</h1></button> */}
              </Container>
              {this.state.pagina}
            </Container>
          </Container>
        </Container>
      );
    }
  }
}
export default painelCLiente;
