export const abrirModalCadastro = function () {
  let modalInteiro = document.getElementById("modalCadastroInteiro");
  modalInteiro.removeAttribute("style");
  modalInteiro.setAttribute("style", "display: flex");
};

export const fecharModalCadastro = function () {
  let modalInteiro = document.getElementById("modalCadastroInteiro");
  modalInteiro.removeAttribute("style");
  modalInteiro.setAttribute("style", "display: none");
};

export const abrirModalLoguin = function () {
  let modalInteiro = document.getElementById("inteiroLogin");
  modalInteiro.removeAttribute("style");
  modalInteiro.setAttribute("style", "display: flex");
};

export const fecharModalLoguin = function () {
  let modalInteiro = document.getElementById("inteiroLogin");
  modalInteiro.removeAttribute("style");
  modalInteiro.setAttribute("style", "display: none");
};
