import React, { Component } from "react";
import "./accordion.css";
import Container from "react-bootstrap/Container";
import { Resposta } from "../../componentesEstilos.js";

class accordion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pausado: "paused",
      aberto: "",
    };
    this.abrirResposta = this.abrirResposta.bind(this);
  }

  abrirResposta() {
    if (this.state.aberto == "fechado") {
      this.setState({ aberto: "aberto" });
      this.setState({ pausado: "running" });
    } else {
      this.setState({ aberto: "fechado" });
      this.setState({ pausado: "running" });
    }
  }
  render() {
    return (
      <Container fluid className="accordion">
        <h3 onClick={this.abrirResposta}>{this.props.titulo}</h3>
        {/* <p className={this.state.aberto}>{this.props.conteudo}</p>    */}
        <Resposta
          className="resposta"
          tamanho={this.props.tamanhoPx}
          pausado={this.state.pausado}
          aberto={this.state.aberto}
        >
          {this.props.conteudo}
        </Resposta>
      </Container>
    );
  }
}

export default accordion;
