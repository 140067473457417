import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import "./index.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import ImgInteira from "../../img/pessoLendo.jpg";

function brindes() {
  return (
    <Container fluid id="inteiro">
      <Header id="cabecalho" />
      <Container fluid id="imagem">
        <Image
          src={ImgInteira}
          id="img2"
          width="1281"
          height="397"
          id="imgCarrosel"
        />
      </Container>
      <Container fluid id="conteudoBrindes">
        <h3>
          Todo mês você receberá um brinde exclusivo da LT junto com seu livro e
          carta, sendo um brinde diferente a cada mês.
        </h3>
      </Container>
      <Footer />
    </Container>
  );
}

export default brindes;
