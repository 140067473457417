import React, { Component } from "react";
import "./painelCliente.css";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import { HashLink as Link } from "react-router-hash-link";
import { urlAssinaturas } from "../../servicos/assinaturas-service";
import Button from "react-bootstrap/Button";
import clienteAlterar from "../../img/pessoaClienteAlterar.png";
import livroCrienteAlterar from "../../img/livroClienteResumo.png";
import clienteAlterarEndereco from "../../img/freteClienteResumo.png";
import cartao from "../../img/cartaoMasterCard.png";
import livroCapa from "../../img/livros/[sun-tzu]-a-arte-da-guerra.jpg";
import { Redirect } from "react-router";
import {
  idAtualLogado,
  entregarPessoaLogada,
  token,
  entregarIdLogado,
  entregarValorEPeriodoPlanoEscolhido,
  pegarValorFrete,
} from "../../servicos/pessoas-service";
import { entregarAssinaturaLogada } from "../../servicos/assinaturas-service";
import Swal from "sweetalert2";
import semLivro from "../../img/semLivro.png";
import ReactIf from "../../rcIf";

class ResumoCliente extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirecionar: false,
      redirecionarAssinatura: false,
      redirecionarEndereco: false,
      redirecionarDados: false,
      redirecionarQuestionario: false,
      pessoaAtual: entregarPessoaLogada,
      idAtual: entregarIdLogado,
      nome: "-",
      cpf: "-",
      telefone: "-",
      email: "-",
      assinatura: 0,
      livroTitulo: "-",
      livroAutor: "-",
      livroImagem: semLivro,
      planoAderido: "-",
      valorParcelado: "-",
      valorFrete: "-",
      planoValorComFrete: "-",
      valorTotal: "-",
      endereco: "-",
      cep: "-",
      cidade: "-",
      qtdParcelas: "-",
      cartaoCredito: "-",
      livroCapa: "-",
      pagamentoStatus: "-",
    };

    this.validarPeriodoPlano = this.validarPeriodoPlano.bind(this);
  }

  validarPeriodoPlano(plano) {
    if (plano == "Mensal") {
      return 1;
    } else if (plano == "Semestral") {
      return 6;
    } else if (plano == "Anual") {
      return 12;
    }
  }

  componentDidMount() {
    //console.log(this.state.idAtual)
    let livro = "/static/media/";
    fetch(urlAssinaturas + "ObterResumoAssinatura", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        assinanteId: this.state.idAtual,
        selecionarResumo: 1,
      }),
    })
      .then((resposta) => resposta.json())
      .then((resultadoJson) => {
        this.setState({ resultado: resultadoJson });

        if (!resultadoJson.hasOwnProperty("assinanteId")) {
          if (
            resultadoJson.mensagem ==
            "Endereço de entrega não encontrado ou inativo"
          ) {
            Swal.fire({
              icon: "question",
              title: "Você ainda não tem um endereço de entrega cadastrado.",
              html: "Gostaria de cadastrar seu endereço?",
              showCancelButton: true,
              showConfirmButton: true,
              cancelButtonColor: "#B29DCA",
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ redirecionarEndereco: true });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.setState({ redirecionar: true });
              }
            });
          } else if (
            resultadoJson.mensagem == "Dados pessoais não cadastrado"
          ) {
            Swal.fire({
              icon: "question",
              title: "Você ainda não cadastrou seus dados pessoais.",
              html: "Gostaria de cadastra-los?",
              showCancelButton: true,
              showConfirmButton: true,
              cancelButtonColor: "#B29DCA",
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ redirecionarDados: true });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.setState({ redirecionar: true });
              }
            });
          } else if (resultadoJson.mensagem == "Questionário não respondido") {
            Swal.fire({
              icon: "question",
              title: "Você ainda não respondeu seu questionário.",
              html: "Gostaria de reponde-lo?",
              showCancelButton: true,
              showConfirmButton: true,
              cancelButtonColor: "#B29DCA",
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ redirecionarQuestionario: true });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.setState({ redirecionar: true });
              }
            });
          } else {
            Swal.fire({
              icon: "question",
              title: "Você ainda não tem uma assinatura valida.",
              html: "Gostaria de criar uma assinatura?",
              showCancelButton: true,
              showConfirmButton: true,
              cancelButtonColor: "#B29DCA",
            }).then((result) => {
              if (result.isConfirmed) {
                this.setState({ redirecionarAssinatura: true });
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                this.setState({ redirecionar: true });
              }
            });
          }
        } else {
          entregarValorEPeriodoPlanoEscolhido(
            resultadoJson.planoValorParcela,
            this.validarPeriodoPlano(resultadoJson.planoPeriodo)
          );
          pegarValorFrete(resultadoJson.planoFrete);
          entregarAssinaturaLogada(resultadoJson.assinaturaId);

          this.setState({
            nome: resultadoJson.assinanteNome,
            cpf: resultadoJson.assinanteCpf,
            telefone: resultadoJson.assinanteTelefone,
            email: resultadoJson.assinanteEmail,
            assinatura: resultadoJson.numeroAssinatura,
            livroTitulo: resultadoJson.ultimoLivroIndicadoTitulo,
            livroAutor: resultadoJson.ultimoLivroIndicadoAutor,
            livroImagem: resultadoJson.ultimoLivroCapaImg
              ? resultadoJson.ultimoLivroCapaImg
              : semLivro,
            planoAderido: resultadoJson.planoAderidoDescricao,
            valorParcelado: parseFloat(resultadoJson.planoValorParcela).toFixed(
              2
            ),
            valorFrete: parseFloat(resultadoJson.planoFrete).toFixed(2),
            planoValorComFrete: parseFloat(resultadoJson.planoTotal).toFixed(2),
            valorTotal: parseFloat(resultadoJson.planoTotal).toFixed(2),
            endereco: resultadoJson.enderecoEntregaRuaNumeroComplemento,
            cep: resultadoJson.enderecoEntregaCep,
            cidade: resultadoJson.enderecoEntregaCidade,
            qtdParcelas: resultadoJson.formaDePagamentoParcelamento
              ? parseInt(resultadoJson.formaDePagamentoParcelamento)
              : "-",
            cartaoCredito: resultadoJson.formaDePagamento
              ? resultadoJson.formaDePagamento
              : "-",
            pagamentoStatus: resultadoJson.formaDePagamentoStatus
              ? resultadoJson.formaDePagamentoStatus
              : "-",
          });
        }
      })

      .catch((err) => {
        console.log("Erro ao se logar: " + err);
      });
  }

  render() {
    if (this.state.redirecionarAssinatura == true) {
      return <Redirect to="/Cadastro" />;
    } else if (this.state.redirecionar == true) {
      return <Redirect to="/Home" />;
    } else if (this.state.redirecionarEndereco == true) {
      return <Redirect to="/cadastroEndereco" />;
    } else if (this.state.redirecionarDados == true) {
      return <Redirect to="/cadastroDadosPessoais" />;
    } else if (this.state.redirecionarQuestionario == true) {
      return <Redirect to="/Questionario" />;
    } else {
      return (
        <Container fluid className="inteiroMainCliente">
          <Container fluid className="mainCliente">
            <Container fluid className="tituloResumoCliente">
              <h2>Olá, {this.state.nome}!</h2>
              <p>Assinatura : #{this.state.assinatura}</p>
            </Container>
            {/* <Container fluid className="ultimoLivroCliente">
              <p>O último livro recomendado para você foi:</p>
              <Container className="containerLivroAtual">
                <Container fluid className="livroAtual">
                  <h2>{this.state.livroTitulo}</h2>
                  <h3>{this.state.livroAutor}</h3>
                  <Link to="#">
                    <h5>Leia aqui seu texto biblioterapêutico deste livro</h5>
                  </Link>
                  <Link to="#">
                    <p>Acesse seu histórico</p>
                  </Link>
                </Container>
                <Container className="imagemLivro">
                  <Image src={this.state.livroImagem} className="livro" />
                </Container>
              </Container>
            </Container> */}
            <Container className="visaoGeral">
              <h2>Visão Geral</h2>
              <Container className="visaoGeralConteudo">
                <Container className="perfil">
                  <Container className="dadosPessoais">
                    <h2>Perfil:</h2>
                    <p>Nome: {this.state.nome}</p>
                    <p>CPF: {this.state.cpf}</p>
                    <p>Telefone: {this.state.telefone}</p>
                    <p>Email: {this.state.email}</p>
                  </Container>
                  <Container className="alterar">
                    <Image src={clienteAlterar} />
                    <Button className="botaoClienteGeralAlterar">
                      Alterar
                    </Button>
                  </Container>
                </Container>
                <Container className="plano">
                  <Container className="planoAderido">
                    <h2>Plano Aderido:</h2>
                    <p>{this.state.planoAderido}</p>
                    <h2 style={{ width: "40px" }}>Valor:</h2>
                    <p>Valor do plano: R$ {this.state.valorParcelado}</p>
                    <p>Frete: R$ {this.state.valorFrete}</p>
                    <p>VALOR TOTAL: R$ {this.state.valorTotal}</p>
                  </Container>
                  <Container className="imgPlanoAderido">
                    <Image src={livroCrienteAlterar} />
                  </Container>
                </Container>
                <Container className="enderecoEntrega">
                  <Container className="dadosPessoais">
                    <h2 style={{ width: "150px" }}>Endereço de Entrega:</h2>
                    <p>{this.state.endereco}</p>
                    <p>CEP {this.state.cep}</p>
                    <p>{this.state.cidade}</p>
                  </Container>
                  <Container className="alterar">
                    <Image src={clienteAlterarEndereco} />
                    <Button className="botaoClienteGeralAlterarEndereço">
                      Alterar
                    </Button>
                  </Container>
                </Container>
                <Container className="formaDePagamento">
                  <ReactIf rcIf={this.state.qtdParcelas > 1}>
                    <Container className="dadosPessoais">
                      <h2 style={{ width: "150px" }}>Forma de Pagamento:</h2>
                      <p>
                        Parcelamento:{" "}
                        {this.state.qtdParcelas > 1
                          ? `${this.state.qtdParcelas} Vezes`
                          : typeof this.state.qtdParcelas == "string"
                          ? "-"
                          : "A vista"}
                      </p>
                      <p>
                        {" "}
                        {this.state.cartaoCredito != ""
                          ? `Cartão de Crédito: ${this.state.cartaoCredito}`
                          : "Boleto"}
                      </p>
                      <p>Valor total: {this.state.valorTotal}</p>
                      <p>Status do pagamento: {this.state.pagamentoStatus}</p>
                    </Container>
                  </ReactIf>
                  <ReactIf rcIf={typeof this.state.qtdParcelas == "string"}>
                    <Container className="alterar">
                      {/* <Image src={cartao} /> */}
                      <h2 style={{ width: "280px" }}>
                        Voçê ainda não tem um pagamento valido
                      </h2>
                      <Button className="botaoClienteGeralAlterarCartao">
                        <Link
                          to="/Pagamento"
                          style={{
                            color: "#fff",
                            "font-family": "Barlow Condensed",
                            "font-weight": "600",
                          }}
                        >
                          Cadastrar
                        </Link>
                      </Button>
                    </Container>
                  </ReactIf>
                </Container>
              </Container>
            </Container>
          </Container>
        </Container>
      );
    }
  }
}

export default ResumoCliente;
