import React, { Component, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "react-bootstrap/Button";
import "./login.css";
import Image from "react-bootstrap/Image";
import Container from "react-bootstrap/Container";
import { Redirect } from "react-router";
import modalImgPessoa from "../../img/modalImgPessoa.png";
import {
  idAtualLogado,
  urlPessoas,
  pessoaModel,
  entregarPessoaLogada,
  pegarToken,
  token,
  idEEmailAtualLogado,
} from "../../servicos/pessoas-service";
import * as modalService from "../../servicos/modal-service.js";
import swal from "sweetalert";

//<span className="circularModal"><CircularProgress /></span>

class login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      senha: "",
      resultado: {},
      idAtual: 0,
      redirecionar: false,
      perfilAtual: 0,
      loading: "unloading",
    };

    this.logarCliente = this.logarCliente.bind(this);
    this.pegarEmail = this.pegarEmail.bind(this);
    this.pegarSenha = this.pegarSenha.bind(this);
    this.chamaLogin = this.chamaLogin.bind(this);
    this.limparInputs = this.limparInputs.bind(this);
  }

  limparInputs() {
    this.setState({ senha: "" });

    let campos = document.querySelectorAll(".modalImput");
    // console.log(campos);
    campos.forEach((e) => e.removeAttribute("disabled"));
  }

  componentDidMount() {}

  pegarEmail(event) {
    // console.log(event.target);
    this.setState({ email: event.target.value });
  }
  pegarSenha(event) {
    this.setState({ senha: event.target.value });
  }

  logarCliente(event) {
    this.setState({ loading: "loading" });
    document.querySelector(".modalSubmitButton").disabled = true;
    document.querySelector(".modalImput").disabled = true;
    // let pessoaLogada = await logar(this.state.email, this.state.senha);
    // console.log(logar(this.state.email, this.state.senha) + "login")
    // console.log(pessoaLogada + "loginvar")

    // console.log(this.state.senha)
    fetch(urlPessoas + "Login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "access-control-expose-headers": "*",
      },
      body: JSON.stringify({
        email: this.state.email,
        senha: this.state.senha,
      }),
    })
      .then((resposta) => {
        pegarToken(resposta.headers.get("Token"));
        return resposta.json();
      })
      .then((resultadoJson) => {
        idAtualLogado(resultadoJson.pessoaId, resultadoJson);
        idEEmailAtualLogado(
          resultadoJson.pessoaId,
          resultadoJson.pessoaEmail,
          resultadoJson
        );
        let perfil = resultadoJson.perfil;
        this.setState({ idAtual: resultadoJson.pessoaId });
        this.setState({ perfilAtual: resultadoJson.perfil });

        this.setState({ loading: "unloading" });
        document.querySelector(".modalSubmitButton").disabled = false;
        if (perfil === 1) {
          this.chamaLogin();
        } else if (perfil === 2) {
          this.chamaLogin();
        } else {
          swal(resultadoJson.mensagem);
          this.limparInputs();
        }
        console.log(entregarPessoaLogada);
      })
      .catch((err) => {
        this.setState({ loading: "unloading" });
        document.querySelector(".modalSubmitButton").disabled = false;
        alert("Erro ao se conectar ao servidor: " + err);
      });

    event.preventDefault();
  }

  chamaLogin() {
    if (
      (this.state.idAtual = !0) ||
      (this.state.idAtual = !null) ||
      (this.state.idAtual = !undefined)
    ) {
      this.setState({ redirecionar: true });
    } else {
    }
  }

  render() {
    if (this.state.redirecionar == true) {
      if (this.state.perfilAtual == 1) {
        return <Redirect to="/PainelDeUsuario" />;
      } else if (this.state.perfilAtual == 2) {
        return <Redirect to="/PainelDeCliente" />;
      }
    } else {
      return (
        <Container
          id="inteiroLogin"
          style={{ display: "none" }}
          onClick={(e) => {
            if (
              e.target.className != "modalSubmitButton" &&
              e.target.id == "inteiroLogin"
            ) {
              modalService.fecharModalLoguin();
              this.limparInputs();
            }
          }}
        >
          <div className={this.state.loading}>
            <CircularProgress />
          </div>
          <div id="modal-login-nucleo">
            <Image
              id="img-modal-login"
              src={modalImgPessoa}
              width="83"
              height="83"
              className="imgModalPessoa"
            />
            <h2>Login</h2>
            <form onSubmit={this.logarCliente} className="modalForm">
              <label className="modalLabel">
                <p style={{ margin: "0 0 0 5px" }}>Seu e-mail:</p>
                <input
                  className="modalImput"
                  type="text"
                  name="email"
                  onChange={(e) => {
                    // console.log("input");
                    this.pegarEmail(e);
                  }}
                  value={this.state.email}
                />
              </label>
              <label className="modalLabel">
                <p style={{ margin: "0 0 0 5px" }}>Sua senha:</p>
                <input
                  className="modalImput"
                  type="password"
                  name="senha"
                  onChange={this.pegarSenha}
                  value={this.state.senha}
                />
              </label>
              <input
                className="modalSubmitButton"
                type="submit"
                value="Entrar"
              ></input>
            </form>
            <span className="esqueciSenha">Esqueci a senha</span>
          </div>

          <div id="login-footer">
            <div id="menuLink">
              <button
                className="botaoModalCadastrar"
                onClick={() => {
                  modalService.fecharModalLoguin();
                  modalService.abrirModalCadastro();
                }}
              >
                <p>Cadastre-se</p>
              </button>
            </div>
            <div>
              <h4 id="textoModalSemCadastro">Ainda não tem conta?</h4>
            </div>
          </div>
        </Container>
      );
    }
  }
}

export default login;
